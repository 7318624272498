import type { FunctionComponent, ReactNode } from 'react'
import styles from './SectionTitle.module.sass'

export interface SectionTitleProps {
	children: ReactNode
}

export const SectionTitle: FunctionComponent<SectionTitleProps> = ({ children }) => {
	return <h2 className={styles.wrapper}>{children}</h2>
}
