import { ContentWithoutRecursionResult } from '../data/ContentWithoutRecursionFragment'
import { ContentRenderer } from './ContentRenderer'
import styles from './HighlightedContent.module.sass'

type HighlightedContentProps = {
	content?: ContentWithoutRecursionResult
}

export const HighlightedContent = ({ content }: HighlightedContentProps) => {
	return (
		<div className={styles.wrapper}>
			{content && <ContentRenderer content={content} containerDisableGutters />}
		</div>
	)
}