import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { CaseStudyLocaleDetailResult } from '../data/CaseStudyLocaleDetailFragment'
import styles from './CaseStudyPage.module.sass'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Hero } from './Hero'
import { useTranslate } from './Translations'

export interface CaseStudyPageProps extends CaseStudyLocaleDetailResult {}

export const CaseStudyPage: FunctionComponent<CaseStudyPageProps> = ({ title, note, content, root }) => {
	const { wideImage, narrowImage } = root ?? {}
	const translate = useTranslate()

	return (
		<div className={styles.wrapper}>
			<Hero wideImage={wideImage} narrowImage={narrowImage}>
				<Container size="wide">
					<h2 className={styles.title}>
						{translate('caseStudy.title.label')}
						<br />
						<b>{title}</b>
					</h2>
					{note && (
						<div className={styles.note}>
							<RichTextRenderer source={note} />
						</div>
					)}
				</Container>
			</Hero>
			{content && (
				<div className={styles.content}>
					<ContentRenderer content={content} />
				</div>
			)}
		</div>
	)
}
