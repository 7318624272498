export const emptyRichTextToNull = (input: string | undefined | null) => {
	if (typeof input === 'string') {
		const parsed = JSON.parse(input)

		if (parsed.children?.length === 1 && parsed.children[0].text === '') {
			return null
		}
	}
	return input ?? null
}
