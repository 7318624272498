import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './Letter.module.sass'
import { LetterClip } from './LetterClip'

export interface LetterProps {
	text: string
	isLarge?: boolean
}

export const Letter: FunctionComponent<LetterProps> = ({ text, isLarge = false }) => {
	return (
		<div className={clsx(styles.wrapper, isLarge && styles.is_large)}>
			<div className={styles.in}>
				<LetterClip>{text}</LetterClip>
			</div>
		</div>
	)
}
