import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './TextWithButton.module.sass'

export interface TextWithButtonProps {
	text: string | undefined
	buttonLink?: LinkResult
	isPrimary?: boolean
}

export const TextWithButton: FunctionComponent<TextWithButtonProps> = ({ text, buttonLink, isPrimary = false }) => {
	return (
		<div className={styles.wrapper}>
			{text && (
				<div className={styles.text}>
					<RichTextRenderer source={text} />
				</div>
			)}
			{buttonLink && (
				<div className={styles.button}>
					<Button
						type="link"
						variant={isPrimary ? 'primary' : 'secondary'}
						{...contemberLinkToHrefTargetRel(buttonLink)}
					>
						{buttonLink.title}
					</Button>
				</div>
			)}
		</div>
	)
}
