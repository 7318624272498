import { FunctionComponent, RefObject, useCallback, useMemo } from 'react'
import { useScroll } from 'react-use'
import { Icon } from './Icon'
import styles from './SlidesArrows.module.sass'
import { useTranslate } from './Translations'

export interface SlidesArrowsProps {
	slidesRef: RefObject<HTMLElement>
}

export const SlidesArrows: FunctionComponent<SlidesArrowsProps> = ({ slidesRef }) => {
	const { x } = useScroll(slidesRef)
	const translate = useTranslate()

	const slide = useCallback(
		(direction: 'left' | 'right') => {
			if (!slidesRef.current) {
				return
			}
			const scrollDistance = Math.min(500, (window.innerWidth / 3) * 2)
			slidesRef.current.scrollBy({
				left: direction === 'left' ? -scrollDistance : scrollDistance,
				behavior: 'smooth',
			})
		},
		[slidesRef],
	)

	const isAtEnd = useMemo(() => {
		// @TODO: make more reactive (ref doesn't trigger recalculation)
		if (!slidesRef.current) {
			return false
		}
		return x + slidesRef.current.clientWidth === slidesRef.current.scrollWidth
	}, [slidesRef, x])
	const isAtStart = x === 0

	return (
		<div className={styles.wrapper}>
			<div className={styles.in}>
				<button
					type="button"
					disabled={isAtStart}
					className={styles.left}
					aria-label={translate('slides.left')}
					onClick={() => {
						slide('left')
					}}
				>
					<Icon name="arrow" />
				</button>
				<button
					type="button"
					disabled={isAtEnd}
					className={styles.right}
					aria-label={translate('slides.right')}
					onClick={() => {
						slide('right')
					}}
				>
					<Icon name="arrow" />
				</button>
			</div>
		</div>
	)
}
