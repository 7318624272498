import { CaseStudyPage } from '../components/CaseStudyPage'
import { FormProvider } from '../components/FormProvider'
import { GenericPage } from '../components/GenericPage'
import { Layout } from '../components/Layout'
import { LocaleProvider } from '../components/LocaleProvider'
import { PositionPage } from '../components/PositionPage'
import { Seo } from '../components/Seo'
import { SpecialLinksProvider } from '../components/SpecialLinksProvider'
import { TranslationsProvider } from '../components/Translations'
import { CaseStudyLocaleDetailFragment } from '../data/CaseStudyLocaleDetailFragment'
import { FooterFragment } from '../data/FooterFragment'
import { GeneralFragment } from '../data/GeneralFragment'
import { GenericPageLocaleFragment } from '../data/GenericPageLocaleFragment'
import { HeaderFragment } from '../data/HeaderFragment'
import { LinkFragment } from '../data/LinkFragment'
import { PositionLocaleDetailFragment } from '../data/PositionLocaleDetailFragment'
import { TranslationDomainFragment } from '../data/TranslationDomainFragment'
import { One } from '../generated/contember'
import { contember, contemberPreview } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { scalarResolver } from '../utilities/createScalarResolver'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import { handleGetStaticPaths, handleGetStaticProps, InferDataLoaderProps } from '../utilities/handlers'
import { transformAndMergeLocalesNavigation } from '../utilities/transformAndMergeLocalesNavigation'
import { transformTranslationsDomain } from '../utilities/transformTranslationsDomain'
import { CookieBar, CookieBarProps } from '../components/CookieBar'
import { BlogPostLocaleDetailFragment } from '../data/BlogPostLocaleDetailFragment'
import { BlogPostPage } from '../components/BlogPostPage'
import { BlogPostCommonFragment } from '../data/BlogPostCommonFragment'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function ({
	translations,
	pages,
	seo,
	header,
	footer,
	specialLinks,
	socialLinks,
	locale,
	locales,
	formPrivacyPolicy,
	formMarketingConsent,
	cookieBar,
	blogPostCommon
}: PageProps) {
	return (
		<TranslationsProvider translations={translations}>
			<LocaleProvider locale={locale}>
				<FormProvider formPrivacyPolicy={formPrivacyPolicy} formMarketingConsent={formMarketingConsent}>
					<SpecialLinksProvider links={specialLinks}>
						<Layout header={header} footer={footer} socialLinks={socialLinks} locale={locale} locales={locales}>
							<Seo {...seo} />
							{pages.generic ? (
								<GenericPage {...pages.generic} />
							) : pages.caseStudy ? (
								<CaseStudyPage {...pages.caseStudy} />
							) : pages.position ? (
								<PositionPage {...pages.position} />
							) : pages.blogPost ? (
								<BlogPostPage {...pages.blogPost} common={blogPostCommon} />
							) : null}
						</Layout>
						<CookieBar {...cookieBar} />
					</SpecialLinksProvider>
				</FormProvider>
			</LocaleProvider>
		</TranslationsProvider>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember('query', { scalars: scalarResolver })({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const url = getLinkableUrlFromContext(context)
	const { locale } = context

	if (!locale) {
		throw new Error('Locale not defined.')
	}

	const fetcher = context.preview ? contemberPreview : contember

	const data = await fetcher('query', { scalars: scalarResolver })({
		getGeneral: [
			{
				by: {
					unique: One.One,
				},
			},
			GeneralFragment(locale),
		],
		getTranslationDomain: [
			{
				by: {
					identifier: 'website',
				},
			},
			TranslationDomainFragment(locale),
		],
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(locale),
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(locale),
		],
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				genericPage: [{}, GenericPageLocaleFragment(locale)],
				caseStudy: [{}, CaseStudyLocaleDetailFragment(locale)],
				position: [{}, PositionLocaleDetailFragment(locale)],
				blogPost: [{}, BlogPostLocaleDetailFragment(locale)],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		getBlogPostCommon: [
			{
				by: {
					unique: One.One,
				}
			},
			BlogPostCommonFragment(locale)
		]
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const pages = {
		generic: data.getLinkable?.genericPage,
		caseStudy: data.getLinkable?.caseStudy,
		position: data.getLinkable?.position,
		blogPost: data.getLinkable?.blogPost,
	}

	const page = Object.values(pages).find((page) => Boolean(page))

	if (!page) {
		return {
			notFound: true,
		}
	}

	const general = data.getGeneral
	const generalLocal = general?.localesByLocale ?? null
	const specialLinks = {
		home: generalLocal?.homeLinkable?.url ?? '/',
		positions: generalLocal?.positionsLink ?? null,
		caseStudies: generalLocal?.caseStudiesLink ?? null,
	}
	const socialLinks = {
		facebookUrl: generalLocal?.facebookUrl ?? null,
		twitterUrl: generalLocal?.twitterUrl ?? null,
		instagramUrl: generalLocal?.instagramUrl ?? null,
		youtubeUrl: generalLocal?.youtubeUrl ?? null,
		linkedinUrl: generalLocal?.linkedinUrl ?? null,
	}

	const cookieBar: CookieBarProps = {
		content: generalLocal?.content ?? null,
		acceptButtonLabel: generalLocal?.acceptButtonLabel ?? null,
		rejectButtonLabel: generalLocal?.rejectButtonLabel ?? null,
	}

	const blogPostCommon = data.getBlogPostCommon

	return {
		props: {
			socialLinks,
			header: data.getHeader,
			footer: data.getFooter,
			pages,
			specialLinks,
			formPrivacyPolicy: generalLocal?.formPrivacyPolicy,
			formMarketingConsent: generalLocal?.formMarketingConsent,
			locale,
			locales: transformAndMergeLocalesNavigation(general?.locales, page.root?.locales),
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneral?.localesByLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(page.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
			cookieBar,
			translations: transformTranslationsDomain(data.getTranslationDomain),
			blogPostCommon,
		},
		revalidate: 60,
	}
})
