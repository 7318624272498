import type { FunctionComponent } from 'react'
import { GenericPageLocaleResult } from '../data/GenericPageLocaleFragment'
import { ContentRenderer } from './ContentRenderer'
import styles from './GenericPage.module.sass'

export interface GenericPageProps extends GenericPageLocaleResult {}

export const GenericPage: FunctionComponent<GenericPageProps> = ({ content }) => {
	return <div className={styles.wrapper}>{content && <ContentRenderer content={content} />}</div>
}
