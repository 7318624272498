import { FunctionComponent, useMemo } from 'react'
import { useLocale } from './LocaleProvider'

export interface TalkDateProps {
	isoDate: string
}

export const TalkDate: FunctionComponent<TalkDateProps> = ({ isoDate }) => {
	const locale = useLocale()
	const date = useMemo(() => {
		const now = new Date()
		const targetDate = new Date(isoDate)
		const formatter = new Intl.DateTimeFormat(locale, {
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			year: now.getFullYear() === targetDate.getFullYear() ? undefined : 'numeric',
		})
		return formatter.format(targetDate)
	}, [isoDate, locale])

	return <time dateTime={isoDate}>{date}</time>
}
