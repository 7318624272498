import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { SubscribeFormResult } from '../data/SubscribeFormFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './CallToAction.module.sass'
import { SubscribeForm } from './SubscribeForm'
import clsx from 'clsx'

export interface CallToActionProps {
	title?: string
	content?: string
	link?: LinkResult
	subscribeForm?: SubscribeFormResult
	variant?: 'primary' | 'secondary' | string
}

export const CallToAction: FunctionComponent<CallToActionProps> = ({ title, variant = 'primary', content, link, subscribeForm }) => {
	return (
		<div className={clsx(styles.wrapper, styles[`is_${variant}`])}>
			{title && <h3 className={styles.title}>{title}</h3>}
			{content && (
				<div className={styles.content}>
					<RichTextRenderer source={content} />
				</div>
			)}
			{subscribeForm && (
				<div className={styles.form}>
					<SubscribeForm submitButtonVariant={variant === 'secondary' ? 'primary' : 'dark'} {...subscribeForm} />
				</div>
			)}
			{link && (
				<div className={styles.action}>
					<Button type="link" variant={variant === 'secondary' ? 'primary' : 'dark'} {...contemberLinkToHrefTargetRel(link)}>
						{link.title}
					</Button>
				</div>
			)}
		</div>
	)
}
