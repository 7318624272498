import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import styles from './BlogPostPage.module.sass'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import { Hero } from './Hero'
import { BlogPostLocaleDetailResult } from '../data/BlogPostLocaleDetailFragment'
import Link from 'next/link'
import { Avatar } from './Avatar'
import { Line } from './Line'
import { InViewAnimation } from './InViewAnimation'
import Image from 'next/image'
import { BlogPosts } from './BlogPosts'
import { Wysiwyg } from './Wysiwyg'
import { useTranslate } from './Translations'
import { BlogPostCommonResult } from '../data/BlogPostCommonFragment'
import { CallToAction } from './CallToAction'
import { ContemberImage } from './ContemberImage'

export interface BlogPostPageProps extends BlogPostLocaleDetailResult {
	common?: BlogPostCommonResult
}

export const BlogPostPage: FunctionComponent<BlogPostPageProps> = ({ title, content, lead, root, common }) => {
	const { wideImage, narrowImage, author, relevantBlogPosts } = root ?? {}
	const translate = useTranslate()

	return (
		<article className={styles.wrapper}>
			<Hero wideImage={wideImage} narrowImage={narrowImage} parallax size="compact">
				<Container size="wide">
					<h2 className={styles.title}>
						{title}
					</h2>
					<div className={styles.authorWithLine}>
						<Line />
						<div className={styles.author}>
							{author?.avatarImage && <div className={styles.avatarWrapper}><Avatar image={author.avatarImage} /></div>}
							<div>
								<address className={styles.authorName}>
									{author?.profileUrl ?
										<Link href={author.profileUrl}>{author?.name}</Link> : author?.name}
								</address>
								<div className={styles.publishedAt}>
									{root?.publishedAt ? <time
										dateTime={root.publishedAt}>{new Date(root.publishedAt).toLocaleDateString()}</time> : 'Unpublished'}
								</div>
							</div>
						</div>
					</div>
					{lead && <div className={styles.lead}>
						<RichTextRenderer source={lead} />
					</div>}
				</Container>
			</Hero>
			{content && (
				<div className={styles.content}>
					<ContentRenderer content={content} disableAnimations />
				</div>
			)}
			{common?.localesByLocale?.ctaTitle && (
				<div className={styles.ctaWrapper}>
					<InViewAnimation>
						<Container>
							<CallToAction title={common?.localesByLocale?.ctaTitle} link={common?.localesByLocale?.ctaLink} />
						</Container>
					</InViewAnimation>
				</div>
			)}
			<Container>
				<InViewAnimation>
					<div className={styles.authorBioWrapper}>
						<div className={styles.authorInfo}>
							<h3 className={styles.authorInfoName}>{author?.name}</h3>
							<h4 className={styles.authorInfoRole}>{author?.localesByLocale?.role}</h4>
							{author?.localesByLocale?.bio && <div className={styles.authorBio}><RichTextRenderer source={author.localesByLocale.bio} /></div>}
						</div>
						{author?.image && (
							<div className={styles.authorImage}>
								<ContemberImage image={author.image} alt={author?.name} />
							</div>
						)}
					</div>
				</InViewAnimation>
			</Container>
			{relevantBlogPosts && relevantBlogPosts.length > 0 && (
				<Container size="wide">
					<InViewAnimation>
						<div className={styles.relatedArticlesWrapper}>
							<Wysiwyg>
								<h3 className={styles.relatedArticlesTitle}>{translate('blogPost.relatedBlogPosts')}</h3>
							</Wysiwyg>
							<BlogPosts blogPosts={relevantBlogPosts.map((item) => item.relevantBlogPost!)} />
						</div>
					</InViewAnimation>
				</Container>
			)}
		</article>
	)
}
