import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import { CSSProperties, FunctionComponent, useState } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { LinkResult } from '../data/LinkFragment'
import { VideoResult } from '../data/VideoFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './ShakyHand.module.sass'
import { useTranslate } from './Translations'
import { VideoModal } from './VideoModal'

export interface ShakyHandProps {
	title?: string
	text?: string
	button?: LinkResult
	smallVideo?: VideoResult
	largeVideo?: VideoResult
	poster?: ImageResult
}

export const ShakyHand: FunctionComponent<ShakyHandProps> = ({
	title,
	text,
	button,
	smallVideo,
	largeVideo,
	poster,
}) => {
	const translate = useTranslate()
	const [isVideoOpen, setIsVideoOpen] = useState(false)

	return (
		<div className={styles.wrapper}>
			{title && <h3 className={styles.title}>{title}</h3>}
			{text && (
				<div className={styles.text}>
					<RichTextRenderer source={text} />
				</div>
			)}
			{button && (
				<div className={styles.button}>
					<Button type="link" {...contemberLinkToHrefTargetRel(button)}>
						{button.title}
					</Button>
				</div>
			)}
			{poster && (
				<div
					className={styles.video}
					style={
						{
							['--ShakyHand-aspectRatio']: `${(poster.width ?? 1) / (poster.height ?? 1)}`,
						} as CSSProperties
					}
				>
					<div className={styles.video_in}>
						<Image className={styles.video_poster} src={poster.url} fill alt={poster.alt ?? ''} />
						{smallVideo?.src && (
							<video className={styles.video_main} autoPlay muted loop>
								<source src={smallVideo.src} type={smallVideo.type} />
							</video>
						)}
						{largeVideo?.src && (
							<>
								<button
									type="button"
									className={styles.video_open}
									aria-label={translate('videoPreview.openFull')}
									onClick={() => setIsVideoOpen(true)}
								/>
								<VideoModal
									isOpen={isVideoOpen}
									onRequestClose={() => {
										setIsVideoOpen(false)
									}}
									video={largeVideo}
								/>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
