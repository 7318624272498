import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { Title, TitleProps } from './Title'
import styles from './TitleWithContent.module.sass'

export interface TitleWithContentProps extends TitleProps {
	content: string
}

export const TitleWithContent: FunctionComponent<TitleWithContentProps> = ({ content, ...otherProps }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<Title {...otherProps} />
			</div>
			<div className={styles.content}>
				<RichTextRenderer source={content} />
			</div>
		</div>
	)
}
