import style from './CookieBar.module.sass'
import { Container } from './Container'
import { Button } from './Button'
import { useEffect } from 'react'
import { ContentResult } from '../data/ContentFragment'
import { ContentRenderer } from './ContentRenderer'
import { useCookieConsent } from './CookieConsentProvider'

export type CookieBarProps = {
	content: ContentResult | null;
	acceptButtonLabel: string | null;
	rejectButtonLabel: string | null;
}

export const CookieBar = ({ content, acceptButtonLabel, rejectButtonLabel }: CookieBarProps) => {
	const { cookieConsentAccepted, onCookieConsentChanged, showCookieBar, onShowCookieBarChanged } = useCookieConsent()

	const onAccept = () => {
		onCookieConsentChanged(true)
		onShowCookieBarChanged(false)
	}

	const onReject = () => {
		onCookieConsentChanged(false)
		onShowCookieBarChanged(false)
	}

	useEffect(() => {
		if (cookieConsentAccepted === null) {
			onShowCookieBarChanged(true)
		}
	}, [cookieConsentAccepted, onShowCookieBarChanged])

	if (!showCookieBar) {
		return null
	}

	return <div className={style.wrapper}>
		<div className={style.container}>
			<Container size="normal">
				{content && <ContentRenderer containerDisableGutters disableAnimations content={content} />}
				<div className={style.buttonContainer}>
					<Button type="button" size="small" variant="text" onClick={onReject}>{rejectButtonLabel}</Button>
					<Button type="button" size="small" onClick={onAccept}>{acceptButtonLabel}</Button>
				</div>
			</Container>
		</div>
	</div>
}