import { RichTextRenderer } from '@contember/react-client'
import { FunctionComponent, useEffect, useState } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { ImageListResult } from '../data/ImageListFragment'
import { A } from './A'
import { Container } from './Container'
import styles from './FrontPageHeader.module.sass'
import { Hero } from './Hero'
import { letterClipTargetClassName } from './LetterClip'

export interface FrontPageHeaderProps {
	title: string | undefined
	note?: string
	wideImages: ImageListResult | undefined
	narrowImages: ImageListResult | undefined
}

export const FrontPageHeader: FunctionComponent<FrontPageHeaderProps> = ({ title, note, wideImages, narrowImages }) => {
	const [randomWideImage] = useState<ImageResult | undefined>(
		wideImages?.items[Math.floor(Math.random() * wideImages.items.length)].image
	)
	const [randomNarrowImage] = useState<ImageResult | undefined>(
		narrowImages?.items[Math.floor(Math.random() * narrowImages.items.length)].image
	)

	return (
		<Hero parallax wideImage={randomWideImage} narrowImage={randomNarrowImage} background={<A />} mobileContentAlignment="center">
			<Container size="wide">
				<div className={styles.wrapper}>
					{title && (
						<h2 className={styles.title}>
							<span className={letterClipTargetClassName}>
								<RichTextRenderer source={title} />
							</span>
						</h2>
					)}
					{note && (
						<div className={styles.note}>
							<span className={letterClipTargetClassName}>
								<RichTextRenderer source={note} />
							</span>
						</div>
					)}
				</div>
			</Container>
		</Hero>
	)
}
