import type { FunctionComponent } from 'react'
import Modal from 'react-modal'
import { useMedia } from 'react-use'
import { VideoResult } from '../data/VideoFragment'
import styles from './VideoModal.module.sass'
import clsx from 'clsx'
import { barlow, barlowCondensed } from '../utilities/fonts'

export interface VideoModalProps {
	isOpen: boolean
	video: VideoResult
	onRequestClose: () => void
}

export const VideoModal: FunctionComponent<VideoModalProps> = ({ isOpen, onRequestClose, video }) => {
	const reducedMotionNoPreference = useMedia('(prefers-reduced-motion: no-preference)', true)

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			closeTimeoutMS={reducedMotionNoPreference ? 300 : 0 /* Same in styles */}
			className={clsx(styles.modal, barlow.variable, barlowCondensed.variable)}
			overlayClassName={styles.modal_overlay}
		>
			<div className={styles.wrapper}>
				<video className={styles.video} autoPlay muted loop controls>
					<source src={video.src} type={video.type} />
				</video>
			</div>
		</Modal>
	)
}
