import { createContext, FunctionComponent, ReactNode, useContext, useMemo } from 'react'
import { GeneralResult } from '../data/GeneralFragment'

type Value = Pick<NonNullable<GeneralResult['localesByLocale']>, 'formPrivacyPolicy' | 'formMarketingConsent'>

const formContext = createContext<Value>({
	formPrivacyPolicy: undefined,
	formMarketingConsent: undefined,
})

export type FormProviderProps = Value & {
	children: ReactNode
}

export const FormProvider: FunctionComponent<FormProviderProps> = ({
	formPrivacyPolicy,
	formMarketingConsent,
	children,
}) => {
	const value = useMemo(() => ({ formPrivacyPolicy, formMarketingConsent }), [formMarketingConsent, formPrivacyPolicy])

	return <formContext.Provider value={value}>{children}</formContext.Provider>
}

export const useForm = () => useContext(formContext)
