import type { FunctionComponent } from 'react'
import styles from './Acronym.module.sass'

export interface AcronymProps {
	words: string[]
}

export const Acronym: FunctionComponent<AcronymProps> = ({ words }) => {
	return (
		<div className={styles.wrapper}>
			{words.map((word, i) => (
				<div key={i} className={styles.word}>
					<div className={styles.letter} aria-hidden>
						{word.substring(0, 1)}
					</div>
					<div className={styles.text}>{word}</div>
				</div>
			))}
		</div>
	)
}
