import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Icon } from './Icon'
import styles from './ShowAllLink.module.sass'

export interface ShowAllLinkProps {
	link: LinkResult
	variant?: 'normal' | 'small'
}

export const ShowAllLink: FunctionComponent<ShowAllLinkProps> = ({ link, variant = 'normal' }) => {
	return (
		<div className={clsx(styles.wrapper, styles[`is_variant_${variant}`])}>
			<Button
				type="link"
				{...contemberLinkToHrefTargetRel(link)}
				size={variant === 'normal' ? 'medium' : 'small'}
				variant={variant === 'normal' ? 'primary' : 'text'}
			>
				{variant === 'small' && (
					<span className={styles.chevron}>
						<Icon name="chevron" />
					</span>
				)}
				{link.title}
			</Button>
		</div>
	)
}
