import clsx from 'clsx'
import {
	ChangeEventHandler,
	CSSProperties,
	DetailedHTMLProps,
	FunctionComponent,
	InputHTMLAttributes,
	ReactNode,
	TextareaHTMLAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from './Input.module.sass'

export type InputProps = Pick<
	DetailedHTMLProps<
		InputHTMLAttributes<HTMLInputElement> | TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLInputElement | HTMLTextAreaElement
	>,
	'name' | 'autoComplete' | 'value' | 'disabled' | 'readOnly' | 'placeholder' | 'required' | 'hidden'
> & {
	onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
	type?: InputHTMLAttributes<HTMLInputElement>['type']
	label: ReactNode
	multiline?: boolean
}

export const Input: FunctionComponent<InputProps> = ({
	name,
	type,
	autoComplete,
	value,
	onChange,
	disabled,
	readOnly,
	placeholder,
	required,
	hidden,
	label,
	multiline = false,
}) => {
	const [minimumHeight, setMinimumHeight] = useState<number>(0)
	const [isFocused, setIsFocused] = useState(false)
	const [localValue, setLocalValue] = useState(typeof value === 'string' ? value : '')
	const isActive = useMemo(() => isFocused || localValue.length > 0, [isFocused, localValue.length])
	const onLocalChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
		(event) => {
			setMinimumHeight(event.currentTarget.scrollHeight)
			setLocalValue(event.target.value)
			onChange?.(event)
		},
		[onChange],
	)

	useEffect(() => {
		if (typeof value === 'string') {
			setLocalValue(value)
		}
	}, [value])

	const Element = multiline ? 'textarea' : 'input'

	return (
		<label
			className={clsx(
				styles.wrapper,
				isActive && styles.is_active,
				isFocused && styles.is_focused,
				styles[`is_type_${type}`],
			)}
			style={
				{
					['--Input-minimumHeight']: `${minimumHeight}px`,
				} as CSSProperties
			}
		>
			<span className={styles.label}>{label}</span>
			<Element
				className={styles.input}
				name={name}
				type={type}
				autoComplete={autoComplete}
				readOnly={readOnly}
				placeholder={placeholder}
				value={localValue}
				onChange={onLocalChange}
				disabled={disabled}
				required={required}
				hidden={hidden}
				onFocus={() => {
					setIsFocused(true)
				}}
				onBlur={() => {
					setIsFocused(false)
				}}
			/>
		</label>
	)
}

Input.displayName = 'Input'
