import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import { ContactRequestOptionResult } from '../data/ContactRequestOptionFragment'
import { ContactRequestOptionType } from '../generated/contember'
import { api } from '../utilities/api'
import { Button } from './Button'
import { CheckboxConsent } from './CheckboxConsent'
import styles from './ContactForm.module.sass'
import { useForm } from './FormProvider'
import { Input } from './Input'
import { useLocale } from './LocaleProvider'
import { SubscribeForm } from './SubscribeForm'
import { useTranslate } from './Translations'

export interface ContactFormProps {
	requestOptions?: ContactRequestOptionResult[]
}

interface ContactFormInProps {
	code: ContactRequestOptionResult['code']
}

const ContactFormIn: FunctionComponent<ContactFormInProps> = ({ code }) => {
	const translate = useTranslate()
	const locale = useLocale()
	const [givenName, setGivenName] = useState('')
	const [familyName, setFamilyName] = useState('')
	const [email, setEmail] = useState('')
	const [text, setText] = useState('')
	const [marketingConsent, setMarketingConsent] = useState(false)
	const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(false)
	const { formPrivacyPolicy, formMarketingConsent } = useForm()

	const mutation = api.contact.send.useMutation({
		onSuccess: () => {
			toast(translate('contactForm.success'), {
				type: 'success',
			})
			setGivenName('')
			setFamilyName('')
			setEmail('')
			setText('')
			setMarketingConsent(false)
			setPrivacyPolicyConsent(false)
		},
		onError: () => {
			toast(translate('contactForm.error'), {
				type: 'error',
			})
		},
	})
	const isLoading = mutation.isLoading
	useMirrorLoading(isLoading)

	return (
		<form
			className={styles.grid}
			onSubmit={(event) => {
				event.preventDefault()
				mutation.mutate({
					locale,
					givenName,
					familyName,
					email,
					text,
					marketingConsent,
					requestOption: code,
				})
			}}
		>
			<div className={styles.narrow}>
				<Input
					label={translate('contactForm.givenName.label')}
					value={givenName}
					onChange={(event) => {
						setGivenName(event.target.value)
					}}
					autoComplete="given-name"
					readOnly={isLoading}
				/>
			</div>
			<div className={styles.narrow}>
				<Input
					label={translate('contactForm.familyName.label')}
					value={familyName}
					onChange={(event) => {
						setFamilyName(event.target.value)
					}}
					autoComplete="family-name"
					readOnly={isLoading}
				/>
			</div>
			<div className={styles.wide}>
				<Input
					required
					label={translate('contactForm.email.label')}
					value={email}
					onChange={(event) => {
						setEmail(event.target.value)
					}}
					autoComplete="email"
					readOnly={isLoading}
				/>
			</div>
			<div className={styles.wide}>
				<Input
					required
					label={translate('contactForm.text.label')}
					value={text}
					onChange={(event) => {
						setText(event.target.value)
					}}
					multiline
					readOnly={isLoading}
				/>
			</div>
			{formPrivacyPolicy && (
				<div className={styles.wide}>
					<CheckboxConsent
						required
						checked={privacyPolicyConsent}
						onChange={(checked) => {
							setPrivacyPolicyConsent(checked)
						}}
						rawRichText={formPrivacyPolicy}
					/>
				</div>
			)}
			{formMarketingConsent && (
				<div className={styles.wide}>
					<CheckboxConsent
						checked={marketingConsent}
						onChange={(checked) => {
							setMarketingConsent(checked)
						}}
						rawRichText={formMarketingConsent}
					/>
				</div>
			)}
			<div className={styles.wide}>
				<Button type="submit" isFullWidth disabled={isLoading}>
					{translate('contactForm.submit')}
				</Button>
			</div>
		</form>
	)
}

export const ContactForm: FunctionComponent<ContactFormProps> = ({ requestOptions = [] }) => {
	const [selectedOptionId, setSelectedOptionId] = useState<string>(() => (requestOptions ?? [])[0]?.id ?? '')
	const selectedOption = useMemo(
		() => requestOptions.find(({ id }) => id === selectedOptionId),
		[requestOptions, selectedOptionId],
	)
	const selectedRequestOptionDescription = useMemo(() => selectedOption?.description, [selectedOption?.description])

	return (
		<div className={styles.wrapper}>
			{requestOptions.length > 0 && (
				<div className={styles.requestOptions}>
					<div className={styles.requestOptions_list}>
						{requestOptions.map(({ id, label }) => (
							<label className={clsx(styles.requestOption, id === selectedOptionId && styles.is_active)} key={id}>
								<input
									type="radio"
									name="requestOption"
									value={id}
									checked={id === selectedOptionId}
									onChange={(event) => {
										setSelectedOptionId(event.target.value)
									}}
									className={styles.requestOption_input}
								/>
								<div className={styles.requestOption_indicator} />
								<div className={styles.requestOption_label}>
									<div className={styles.requestOption_label_in}>{label}</div>
								</div>
							</label>
						))}
					</div>
					<div className={styles.requestOptions_track} />
					{selectedRequestOptionDescription && (
						<div className={styles.requestOptions_description}>
							<RichTextRenderer source={selectedRequestOptionDescription} />
						</div>
					)}
				</div>
			)}
			<div className={styles.form}>
				{selectedOption?.type === ContactRequestOptionType.contact && <ContactFormIn code={selectedOption?.code} />}
				{selectedOption?.type === ContactRequestOptionType.subscribe && selectedOption.subscribeForm && (
					<SubscribeForm {...selectedOption.subscribeForm} />
				)}
			</div>
		</div>
	)
}
