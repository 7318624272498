import { FunctionComponent } from 'react'
import { OpenPositionsProps } from './OpenPositions'
import styles from './PositionCategory.module.sass'
import { Positions } from './Positions'

type Category = NonNullable<OpenPositionsProps['categories'][number]['item']>
export interface PositionCategoryProps {
	title: NonNullable<Category['localesByLocale']>['title'] | undefined
	positions: Category['positions']
	openPositionId: string | null
	setOpenPositionId: (id: string | null) => void
}

export const PositionCategory: FunctionComponent<PositionCategoryProps> = ({
	title,
	positions,
	openPositionId,
	setOpenPositionId,
}) => {
	return (
		<div className={styles.wrapper}>
			{title && <h3 className={styles.title}>{title}</h3>}
			<div className={styles.items}>
				<Positions positions={positions} openPositionId={openPositionId} setOpenPositionId={setOpenPositionId} />
			</div>
		</div>
	)
}
