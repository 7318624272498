import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { flattenCaseStudyListFragment } from '../data/CaseStudyListFragment'
import { CaseStudyTile } from './CaseStudyTile'
import styles from './SelectedCaseStudies.module.sass'
import { ShowAllLink } from './ShowAllLink'
import { useCaseStudiesLink } from './SpecialLinksProvider'

export interface SelectedCaseStudiesProps {
	items: ReturnType<typeof flattenCaseStudyListFragment>
}

export const SelectedCaseStudies: FunctionComponent<SelectedCaseStudiesProps> = ({ items }) => {
	const isFirstHighlighted = items.length > 3
	const showAllLink = useCaseStudiesLink()

	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{items.map(({ id, caseStudy }, i) => (
					<div key={id} className={clsx(styles.item, isFirstHighlighted && i === 0 && styles.is_highlighted)}>
						<CaseStudyTile {...caseStudy} layout={isFirstHighlighted && i === 0 ? 'wide' : 'narrow'} />
					</div>
				))}
			</div>
			{showAllLink && <ShowAllLink link={showAllLink} />}
		</div>
	)
}
