import { GraphQLTypes, InputType, Selector } from '../generated/contember'
import { scalarResolver } from '../utilities/createScalarResolver'
import { isDefined } from '../utilities/isDefined'
import { CaseStudyLocalePreviewFragment } from './CaseStudyLocalePreviewFragment'

export function CaseStudyListFragment(locale: string) {
	return Selector('CaseStudyList')({
		items: [
			{},
			{
				id: true,
				caseStudy: [
					{},
					{
						localesByLocale: [
							{
								by: {
									locale: {
										code: locale,
									},
								},
							},
							CaseStudyLocalePreviewFragment(locale),
						],
					},
				],
			},
		],
	})
}

export type CaseStudyListResult = InputType<
	GraphQLTypes['CaseStudyList'],
	ReturnType<typeof CaseStudyListFragment>,
	typeof scalarResolver
>

export const flattenCaseStudyListFragment = (result: CaseStudyListResult | undefined) =>
	result?.items
		.map(
			({ id, caseStudy }) =>
				caseStudy?.localesByLocale && {
					id,
					caseStudy: caseStudy.localesByLocale,
				},
		)
		.filter(isDefined) ?? []
