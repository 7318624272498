import { RichTextRenderer } from '@contember/react-client'
import { ChangeEvent, FunctionComponent, useCallback } from 'react'
import { Checkbox } from './Checkbox'
import styles from './CheckboxConsent.module.sass'

export interface CheckboxConsentProps {
	required?: boolean
	checked: boolean
	onChange: (checked: boolean) => void
	rawRichText: string
}

export const CheckboxConsent: FunctionComponent<CheckboxConsentProps> = ({
	required = false,
	checked,
	onChange,
	rawRichText,
}) => {
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			onChange(event.target.checked)
		},
		[onChange],
	)

	return (
		<div className={styles.wrapper}>
			<Checkbox required={required} blandLinks checked={checked} onChange={handleChange}>
				<RichTextRenderer source={rawRichText} />
			</Checkbox>
		</div>
	)
}
