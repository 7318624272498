import { FunctionComponent, useState } from 'react'
import { ContentBlockReferencesResult } from '../data/ContentBlockReferencesFragment'
import { isDefined } from '../utilities/isDefined'
import styles from './OpenPositions.module.sass'
import { PositionCategory } from './PositionCategory'

export interface OpenPositionsProps {
	categories: ContentBlockReferencesResult['positionCategories']
}

export const OpenPositions: FunctionComponent<OpenPositionsProps> = ({ categories }) => {
	const [openPositionId, setOpenPositionId] = useState<null | string>(null)

	return (
		<div className={styles.wrapper}>
			<div className={styles.categories}>
				{categories.map(({ id, item }) => {
					if (!item) {
						return null
					}
					const positions = item.positions.map((item) => item.localesByLocale).filter(isDefined)
					if (positions.length === 0) {
						return null
					}
					return (
						<div key={id} className={styles.category}>
							<PositionCategory
								title={item.localesByLocale?.title}
								positions={positions}
								openPositionId={openPositionId}
								setOpenPositionId={setOpenPositionId}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}
