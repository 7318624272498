import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { CaseStudyLocalePreviewResult } from '../data/CaseStudyLocalePreviewFragment'
import { ImageResult } from '../data/ImageFragment'
import styles from './CaseStudyBlock.module.sass'
import { CaseStudyTile } from './CaseStudyTile'

export interface CaseStudyBlockProps {
	title?: string
	description?: string
	image?: ImageResult
	flipSides?: boolean
	caseStudy?: CaseStudyLocalePreviewResult
}

export const CaseStudyBlock: FunctionComponent<CaseStudyBlockProps> = ({
	title,
	description,
	image,
	caseStudy,
	flipSides = false,
}) => {
	return (
		<div className={clsx(styles.wrapper, flipSides && styles.is_flipSides)}>
			{title && (
				<div className={styles.title}>
					<h2 className={styles.title_in}>
						<RichTextRenderer source={title} />
					</h2>
				</div>
			)}
			{image && (
				<div className={styles.image}>
					<Image
						className={styles.image_in}
						src={image.url}
						width={image.width ?? 1}
						height={image.height ?? 1}
						alt={image.alt ?? ''}
						sizes="(min-width: 800px) 800px, 100vw"
					/>
				</div>
			)}
			{description && (
				<div className={styles.description}>
					<RichTextRenderer source={description} />
				</div>
			)}
			{caseStudy && (
				<div className={styles.tile}>
					<CaseStudyTile layout="narrow" {...caseStudy} />
				</div>
			)}
		</div>
	)
}
