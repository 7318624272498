import Image, { ImageProps } from 'next/image'
import { ImageResult } from '../data/ImageFragment'

export type ContemberImageProps = Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'> & {
	image: ImageResult
	alt?: string
}

export const ContemberImage = ({ image, alt, ...imageProps }: ContemberImageProps) => (
	<Image
		{...imageProps}
		src={image.url}
		alt={alt ?? image.alt ?? ''}
		width={imageProps.fill ? undefined : image.width}
		height={imageProps.fill ? undefined : image.height}
	/>
)