import { useMemo } from 'react'
import type { ContentWithoutRecursionResult } from '../data/ContentWithoutRecursionFragment'

export const useContentRendererCopyPasteBugWorkaround = (blocks: ContentWithoutRecursionResult['blocks']) =>
	useMemo(() => {
		const allReferences: ContentWithoutRecursionResult['blocks'][number]['references'] = []

		return blocks.map((block) => {
			allReferences.push(...block.references)

			return { ...block, references: allReferences }
		})
	}, [blocks])
