import clsx from 'clsx'
import Link from 'next/link'
import type { ComponentProps, FunctionComponent } from 'react'
import styles from './Tag.module.sass'

export interface TagProps {
	isActive?: boolean
	isFilled?: boolean
	label: string | undefined
	href?: ComponentProps<typeof Link>['href']
}

export const Tag: FunctionComponent<TagProps> = ({ label, href, isActive = false, isFilled = false }) => {
	const className = clsx(styles.wrapper, isActive && styles.is_active, isFilled && styles.is_filled)

	if (href) {
		return (
			<Link className={className} href={href} shallow>
				{label}
			</Link>
		)
	}

	return <span className={className}>{label}</span>
}
