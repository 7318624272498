import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { InformationListResult } from '../data/InformationListFragment'
import styles from './Values.module.sass'

export interface ValuesProps {
	title?: string
	items: InformationListResult['items']
}

export const Values: FunctionComponent<ValuesProps> = ({ title, items }) => {
	return (
		<div className={styles.wrapper}>
			{title && (
				<h3 className={styles.title}>
					<RichTextRenderer source={title} />
				</h3>
			)}
			<div className={styles.list}>
				{items.map((item) => (
					<div key={item.id} className={styles.item}>
						{item.title && (
							<h4 className={styles.item_title} data-first={item.title.substring(0, 1)}>
								{item.title}
							</h4>
						)}
						{item.description && (
							<div className={styles.item_description}>
								<RichTextRenderer source={item.description} />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
