import { RefObject, useEffect, useState } from 'react'
import { useIntersection } from 'react-use'

export const useIsInViewOnce = (ref: RefObject<HTMLElement>) => {
	const [isInViewOnce, setIsInViewOnce] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true)
		}, 200)
	}, [])

	const intersection = useIntersection(ref, {
		threshold: 0,
	})
	useEffect(() => {
		if (intersection?.isIntersecting) {
			setIsInViewOnce(true)
		}
	}, [intersection?.isIntersecting])

	return isInViewOnce || !isLoaded
}
