import type { FunctionComponent } from 'react'
import { BusinessCardListResult } from '../data/BusinessCardListFragment'
import { Avatar } from './Avatar'
import styles from './BusinessCardList.module.sass'

export interface BusinessCardListProps {
	items: BusinessCardListResult['items']
}

export const BusinessCardList: FunctionComponent<BusinessCardListProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			{items.map(
				({ id, businessCard }) =>
					businessCard && (
						<div key={id} className={styles.item}>
							{businessCard.avatar && (
								<div className={styles.avatar}>
									<Avatar image={businessCard.avatar} />
								</div>
							)}
							{businessCard.name && <h4 className={styles.name}>{businessCard.name}</h4>}
							{businessCard.role && <div className={styles.role}>{businessCard.role}</div>}
							{businessCard.email && (
								<div className={styles.email}>
									<a className={styles.email_link} href={`mailto:${businessCard.email}`}>
										{businessCard.email}
									</a>
								</div>
							)}
							{businessCard.phone && (
								<div className={styles.phone}>
									<a className={styles.phone_link} href={`tel:${businessCard.phone}`}>
										{businessCard.phone}
									</a>
								</div>
							)}
						</div>
					),
			)}
		</div>
	)
}
