import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { letterClipTargetClassName } from './LetterClip'
import styles from './TitleWithText.module.sass'

export interface TitleWithTextProps {
	title: string | undefined
	text?: string
}

export const TitleWithText: FunctionComponent<TitleWithTextProps> = ({ title, text }) => {
	return (
		<div className={styles.wrapper}>
			{title && (
				<h2 className={styles.title}>
					<span className={letterClipTargetClassName}>
						<RichTextRenderer source={title} />
					</span>
				</h2>
			)}
			{text && (
				<div className={styles.text}>
					<span className={letterClipTargetClassName}>
						<RichTextRenderer source={text} />
					</span>
				</div>
			)}
		</div>
	)
}
