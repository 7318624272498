import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { TalkResult } from '../data/TalkFragment'
import { TalkListsResult } from '../data/TalkListsFragment'
import { isDefined } from '../utilities/isDefined'
import { Button } from './Button'
import { Container } from './Container'
import { TalkDate } from './TalkDate'
import styles from './TalkList.module.sass'
import { TalkModal } from './TalkModal'
import { useTranslate } from './Translations'

export interface TalkListsProps {
	listsItems: TalkListsResult['items']
}

export const TalkLists: FunctionComponent<TalkListsProps> = ({ listsItems }) => {
	const translate = useTranslate()
	const hashPrefix = translate('talkList.hashPrefix')
	const { asPath, push } = useRouter()
	const hash = useMemo(() => asPath.split('#')[1] ?? '', [asPath])
	const [activeTalkSlug, setActiveTalkSlug] = useState('')
	const [modal, setModal] = useState<{
		talkSlug: string
		isOpen: boolean
	}>({
		talkSlug: '',
		isOpen: false,
	})

	useEffect(() => {
		setActiveTalkSlug(hash.startsWith(hashPrefix) ? hash.substring(hashPrefix.length) : '')
	}, [hash, hashPrefix])

	useEffect(() => {
		setModal((state) =>
			activeTalkSlug
				? {
						talkSlug: activeTalkSlug,
						isOpen: true,
				  }
				: {
						talkSlug: state.talkSlug,
						isOpen: false,
				  },
		)
	}, [activeTalkSlug])
	const modalTalk = useMemo(
		() =>
			listsItems.map(({ items }) => items.find((item) => item.talk?.slug === modal.talkSlug)).filter(isDefined)[0]
				?.talk,
		[listsItems, modal.talkSlug],
	)

	return (
		<div className={styles.wrapper}>
			<TalkModal
				isOpen={modal.isOpen}
				onRequestClose={() => {
					push(asPath.split('#')[0], undefined, { shallow: true, scroll: false })
				}}
				talk={modalTalk}
			/>
			{listsItems.map(({ id, title, items }) => (
				<div className={styles.group} key={id}>
					{title && (
						<Container disableGutters>
							<h3 className={styles.group_title}>{title}</h3>
						</Container>
					)}
					<div className={styles.group_items}>
						{items.map(({ id, talk }) => talk && <Item key={id} talk={talk} hashPrefix={hashPrefix} />)}
					</div>
				</div>
			))}
		</div>
	)
}

const Item: FunctionComponent<{
	talk: TalkResult
	hashPrefix: string
}> = ({ talk, hashPrefix }) => {
	const href = talk.slug ? `#${hashPrefix}${talk.slug}` : null
	const translate = useTranslate()

	return (
		<div className={styles.item}>
			<div className={styles.item_background}>
				{talk.coverImage && (
					<Image
						src={talk.coverImage.url}
						alt={talk.coverImage.alt ?? ''}
						fill
						className={styles.item_background_in}
						sizes="400px"
					/>
				)}
			</div>
			{talk.date && (
				<div className={styles.item_date}>
					<TalkDate isoDate={talk.date} />
				</div>
			)}
			{talk.title && <h4 className={styles.item_title}>{talk.title}</h4>}
			{talk.note && (
				<div className={styles.item_note}>
					<RichTextRenderer source={talk.note} />
				</div>
			)}
			{href && (
				<div className={styles.item_action}>
					<div className={styles.item_action_button}>
						<Button type="link" href={href} variant="secondary" size="small">
							{translate('talkList.more')}
						</Button>
					</div>
					<Link href={href} className={styles.item_action_link} aria-label="" />
				</div>
			)}
		</div>
	)
}
