import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const tagSlugParameterName = 'tag'

export const useActiveTagSlugParameter = () => {
	const router = useRouter()
	const activeSlug = useMemo(() => {
		const activeSlug = router.query[tagSlugParameterName]
		if (typeof activeSlug === 'string') {
			return activeSlug
		}
		return null
	}, [router.query])

	return activeSlug
}
