import type { FunctionComponent } from 'react'
import { PositionLocaleDetailResult } from '../data/PositionLocaleDetailFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import styles from './PositionDetail.module.sass'

export interface PositionDetailProps extends PositionLocaleDetailResult {}

export const PositionDetail: FunctionComponent<PositionDetailProps> = ({ title, salaryRange, content }) => {
	return (
		<Container>
			<div className={styles.wrapper}>
				{title && <h2 className={styles.title}>{title}</h2>}
				{salaryRange && (<div className={styles.salary_range}>{salaryRange}</div>)}
				{content && (
					<div className={styles.content}>
						<ContentRenderer containerDisableGutters content={content} />
					</div>
				)}
			</div>
		</Container>
	)
}
