import type { FunctionComponent } from 'react'
import { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { SectionTitle } from './SectionTitle'
import { Tag } from './Tag'
import styles from './Tags.module.sass'

export interface TagsProps {
	title?: string
	tags: Array<{
		id: string
		link: LinkResult
	}>
	link?: LinkResult
}

export const Tags: FunctionComponent<TagsProps> = ({ title, tags, link }) => {
	return (
		<div className={styles.wrapper}>
			{title && <SectionTitle>{title}</SectionTitle>}
			<div className={styles.list}>
				{tags.map(({ id, link }) => (
					<Tag key={id} label={link.title} {...contemberLinkToHrefTargetRel(link)} />
				))}
			</div>
			{link && (
				<div className={styles.link}>
					<Button type="link" {...contemberLinkToHrefTargetRel(link)}>
						{link.title}
					</Button>
				</div>
			)}
		</div>
	)
}
