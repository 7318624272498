import type { FunctionComponent } from 'react'
import { EmbedResult } from '../data/EmbedFragment'
import { EmbedType } from '../generated/contember'
import styles from './Embed.module.sass'
import { Vimeo } from './Vimeo'
import { Youtube } from './Youtube'

export interface EmbedProps extends EmbedResult {}

export const Embed: FunctionComponent<EmbedProps> = ({ type, embedId }) => {
	if (!embedId) {
		return null
	}
	return (
		<div className={styles.wrapper}>
			{type === EmbedType.youtube ? (
				<Youtube videoId={embedId} />
			) : type === EmbedType.vimeo ? (
				<Vimeo videoId={embedId} />
			) : null}
		</div>
	)
}
