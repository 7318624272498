import { ContentBlockReferencesResult } from '../data/ContentBlockReferencesFragment'
import { ResponsiveImage } from './ResponsiveImage'
import styles from './ImageWithCaption.module.sass'
import { RichTextRenderer } from '@contember/react-client'

export interface ImageWithCaptionProps {
	image: NonNullable<ContentBlockReferencesResult['image']>
	caption: NonNullable<ContentBlockReferencesResult['primaryText']>
}

export const ImageWithCaption = ({ image, caption }: ImageWithCaptionProps) => {
	return (
		<div>
			<ResponsiveImage src={image.url} alt={image.alt ?? ''} width={image.width} height={image.height} />
			<div className={styles.caption}><RichTextRenderer source={caption} /></div>
		</div>
	)
}