import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { InformationListResult } from '../data/InformationListFragment'
import { LinkResult } from '../data/LinkFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import styles from './Statistics.module.sass'

export interface StatisticsProps {
	link?: LinkResult
	items: InformationListResult['items']
}

export const Statistics: FunctionComponent<StatisticsProps> = ({ items, link }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{items.map((item) => (
					<div key={item.id} className={styles.item}>
						{item.title && <h4 className={styles.title}>{item.title}</h4>}
						{item.description && (
							<div className={styles.description}>
								<RichTextRenderer source={item.description} />
							</div>
						)}
					</div>
				))}
			</div>
			{link && (
				<div className={styles.more}>
					<Link {...contemberLinkToHrefTargetRel(link)} className={styles.more_link}>
						{link.title}
					</Link>
				</div>
			)}
		</div>
	)
}
