import { useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'

export const useParallax = () => {
	const targetParallax = useRef({ x: 0, y: 0 })
	const [parallax, setParallax] = useState({ x: 0, y: 0 }) // Numbers between -1 and 1
	const isFinePointer = useMedia('(pointer: fine)', true)
	const isReducedMotionPrefered = useMedia('(prefers-reduced-motion)', true)

	useEffect(() => {
		if (isReducedMotionPrefered) {
			return
		}
		if (isFinePointer) {
			const handleMouseMove = (event: MouseEvent) => {
				const { clientX, clientY } = event
				const { innerWidth, innerHeight } = window
				const x = -1 * ((clientX / innerWidth) * 2 - 1)
				const y = -1 * ((clientY / innerHeight) * 2 - 1)
				targetParallax.current = { x, y }
			}

			window.addEventListener('mousemove', handleMouseMove)
			return () => {
				window.removeEventListener('mousemove', handleMouseMove)
			}
		} else {
			const handleOrientationChange = (event: DeviceOrientationEvent) => {
				const gamma = (event.gamma ?? 0) / 90
				const beta = (event.beta ?? 0) / 180
				const { orientation } = window
				const angle = {
					x: 0,
					y: 0,
				}
				if (orientation === undefined || orientation === 0) {
					angle.x = gamma
					angle.y = beta
				} else if (orientation === 180) {
					angle.x = -gamma
					angle.y = -beta
				} else if (orientation === 90) {
					angle.x = beta
					angle.y = -gamma
				} else if (orientation === -90) {
					angle.x = -beta
					angle.y = gamma
				}
				targetParallax.current = angle
			}
			window.addEventListener('deviceorientation', handleOrientationChange)
			return () => {
				window.removeEventListener('deviceorientation', handleOrientationChange)
			}
		}
	}, [isFinePointer, isReducedMotionPrefered])

	useEffect(() => {
		let timer: ReturnType<typeof requestAnimationFrame>
		const ease = (original: { x: number; y: number }, target: { x: number; y: number }) => {
			const easeComponent = (original: number, target: number) => original + (target - original) * 0.2
			return {
				x: easeComponent(original.x, target.x),
				y: easeComponent(original.y, target.y),
			}
		}
		const loop = () => {
			setParallax((originalOffset) => ease(originalOffset, targetParallax.current))
			timer = requestAnimationFrame(loop)
		}
		loop()
		return () => {
			cancelAnimationFrame(timer)
		}
	}, [])

	return parallax
}
