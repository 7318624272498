import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { ComponentProps, createContext, FunctionComponent, ReactNode, useCallback, useContext } from 'react'
import { flattenCaseStudyListFragment } from '../data/CaseStudyListFragment'
import { ContentBlockResult } from '../data/ContentBlockFragment'
import { ContentBlockWithoutRecursionResult } from '../data/ContentBlockWithoutRecursionFragment'
import { ContentWithoutRecursionResult } from '../data/ContentWithoutRecursionFragment'
import { ContentReferenceType } from '../generated/contember'
import { emptyRichTextToNull } from '../utilities/emptyRichTextToNull'
import { isDefined } from '../utilities/isDefined'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { Acronym } from './Acronym'
import { BusinessCardList } from './BusinessCardList'
import { CallToAction } from './CallToAction'
import { CaseStudies } from './CaseStudies'
import { CaseStudyBlock } from './CaseStudyBlock'
import { Catchwords } from './Catchwords'
import { ContactBlock } from './ContactBlock'
import { ContactForm } from './ContactForm'
import { Container, ContainerProps } from './Container'
import styles from './ContentRenderer.module.sass'
import { Embed } from './Embed'
import { EventHeader } from './EventHeader'
import { FrontPageHeader } from './FrontPageHeader'
import { Gallery } from './Gallery'
import { DisableInViewAnimations, InViewAnimation } from './InViewAnimation'
import { Letter } from './Letter'
import { Line } from './Line'
import { Lock } from './Lock'
import { LogoList } from './LogoList'
import { OpenPositions } from './OpenPositions'
import { ResponsiveImage } from './ResponsiveImage'
import { SelectedCaseStudies } from './SelectedCaseStudies'
import { ShakyHand } from './ShakyHand'
import { SpecificPositions } from './SpecificPositions'
import { Statistics } from './Statistics'
import { Tags } from './Tags'
import { TalkLists } from './TalkLists'
import { Testimonials } from './Testimonials'
import { TextWithButton } from './TextWithButton'
import { Title } from './Title'
import { TitleWithContent } from './TitleWithContent'
import { TitleWithText } from './TitleWithText'
import { Values } from './Values'
import { Wysiwyg } from './Wysiwyg'
import { TestimonialsFull } from './TestimonialsFull'
import { BlogPosts } from './BlogPosts'
import { ImageWithCaption } from './ImageWithCaption'
import { HighlightedContent } from './HighlightedContent'

export interface ContentRendererProps {
	content: ContentWithoutRecursionResult
	containerDisableGutters?: boolean
	disableAnimations?: boolean
}

type Block = ReferenceRendererProps<
	ContentBlockResult['references'][number]
>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget']

const BlockContainer: FunctionComponent<Omit<ContainerProps, 'disableGutters'>> = (props) => {
	const disableGutters = useContainerDisableGutters()

	return <Container disableGutters={disableGutters} {...props} />
}

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	image: function image({ reference }) {
		return (
			reference.image && (
				<InViewAnimation>
					<BlockContainer>
						<ResponsiveImage
							src={reference.image.url}
							width={reference.image.width}
							height={reference.image.height}
							alt={reference.image.alt ?? ''}
							sizes="(min-width: 800px) 800px, 100vw"
						/>
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	imageWithCaption: function imageWithCaption({ reference }) {
		return reference.image && (
			<InViewAnimation>
				<BlockContainer>
					<ImageWithCaption image={reference.image} caption={reference.primaryText ?? ''} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	acronym: function acronym({ reference }) {
		const words = reference.primaryText?.split('\n') ?? []
		return (
			words.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<Acronym words={words} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	caseStudies: function caseStudies({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<CaseStudies caseStudies={reference.caseStudies} tags={reference.tags} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	featuredCaseStudies: function featuredCaseStudies({ reference }) {
		const items = flattenCaseStudyListFragment(reference.featuredCaseStudies)
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<SelectedCaseStudies items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	relatedCaseStudies: function relatedCaseStudies({ reference }) {
		const items = flattenCaseStudyListFragment(reference.featuredCaseStudies)
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<SelectedCaseStudies items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	openPositions: function openPositions({ reference }) {
		if (!('positionCategories' in reference)) {
			throw new Error('This should never happen.')
		}
		const categories = reference.positionCategories
		return (
			categories.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<OpenPositions categories={categories} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	specificPositions: function specificPositions({ reference }) {
		if (!('positions' in reference)) {
			throw new Error('This should never happen.')
		}
		const items = reference.positions?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<SpecificPositions items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	tags: function tags({ reference }) {
		const tags = (reference.links?.items ?? [])
			.map(
				({ id, link }) =>
					link && {
						id,
						link,
					},
			)
			.filter(isDefined)
		return (
			<InViewAnimation>
				<BlockContainer>
					<Tags title={reference.primaryText} tags={tags} link={reference.link} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	catchwords: function catchwords({ reference }) {
		const items = reference.informationList?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<Catchwords items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	values: function values({ reference }) {
		const items = reference.informationList?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<Values title={reference.primaryText} items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	statistics: function statistics({ reference }) {
		const items = reference.informationList?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<Statistics items={items} link={reference.link} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	embed: function embed({ reference }) {
		return (
			reference.embed && (
				<InViewAnimation>
					<BlockContainer>
						<Embed {...reference.embed} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	talkList: function talkList({ reference }) {
		const listsItems = reference.talkLists?.items ?? []
		return (
			listsItems.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<TalkLists listsItems={listsItems} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	callToAction: function callToAction({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="normal">
					<CallToAction
						title={reference.primaryText}
						content={reference.secondaryText}
						link={reference.link}
						subscribeForm={reference.subscribeForm}
						variant={reference.variant}
					/>
				</BlockContainer>
			</InViewAnimation>
		)
	},
	businessCardList: function businessCardList({ reference }) {
		const items = reference.businessCardList?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<BlockContainer size="wide">
						<BusinessCardList items={items} />
					</BlockContainer>
				</InViewAnimation>
			)
		)
	},
	contact: function contact({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<ContactBlock phone={reference.primaryText} email={reference.secondaryText} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	testimonials: function testimonials({ reference }) {
		const items = reference.testimonials?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<Testimonials items={items} />
				</InViewAnimation>
			)
		)
	},
	testimonialsFull: function testimonialsFull({ reference }) {
		const items = reference.testimonialsFull?.items ?? []
		return (
			items.length > 0 && (
				<BlockContainer size="wide">
					<TestimonialsFull items={items} />
				</BlockContainer>
			)
		)
	},
	gallery: function gallery({ reference }) {
		const items = reference.images?.items ?? []
		return (
			items.length > 0 && (
				<InViewAnimation>
					<Gallery items={items} />
				</InViewAnimation>
			)
		)
	},
	contactForm: function contactForm({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<ContactForm requestOptions={reference.contactRequestOptions?.items} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	title: function title({ reference }) {
		const content = emptyRichTextToNull(reference.tertiaryText)
		return (
			<InViewAnimation>
				<BlockContainer size="ultraWide">
					{content ? (
						<TitleWithContent
							primary={reference.primaryText}
							secondary={reference.secondaryText}
							content={content}
							secondaryFirst={reference.alternative}
						/>
					) : (
						<Title
							primary={reference.primaryText}
							secondary={reference.secondaryText}
							secondaryFirst={reference.alternative}
						/>
					)}
				</BlockContainer>
			</InViewAnimation>
		)
	},
	logoList: function logoList({ reference }) {
		const lightThemeImages = reference.images?.items ?? []
		const darkThemeImages = reference.otherImages?.items ?? []

		return (
			<InViewAnimation>
				<LogoList title={reference.primaryText} lightThemeImages={lightThemeImages} darkThemeImages={darkThemeImages} />
			</InViewAnimation>
		)
	},
	eventHeader: function eventHeader({ reference }) {
		const links = reference.links?.items ?? []
		return (
			<InViewAnimation>
				<EventHeader
					title={reference.primaryText}
					note={reference.secondaryText}
					links={links}
					wideImage={reference.image}
					narrowImage={reference.otherImage}
				/>
			</InViewAnimation>
		)
	},
	frontPageHeader: function frontPageHeader({ reference }) {
		return (
			<FrontPageHeader
				title={reference.primaryText}
				note={reference.secondaryText}
				wideImages={reference.images}
				narrowImages={reference.otherImages}
			/>
		)
	},
	lock: function lock() {
		return (
			<InViewAnimation>
				<BlockContainer>
					<Lock />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	line: function line() {
		return (
			<InViewAnimation>
				<BlockContainer>
					<Line />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	caseStudy: function caseStudy({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<CaseStudyBlock
						title={reference.primaryText}
						description={reference.secondaryText}
						image={reference.image}
						caseStudy={reference.caseStudy?.localesByLocale}
						flipSides={reference.alternative}
					/>
				</BlockContainer>
			</InViewAnimation>
		)
	},
	letter: function letter({ reference }) {
		const text = reference.primaryText
		return (
			text && (
				<InViewAnimation>
					<Letter text={text} />
				</InViewAnimation>
			)
		)
	},
	textWithButton: function textWithButton({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<TextWithButton text={reference.primaryText} buttonLink={reference.link} isPrimary={reference.alternative} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	titleWithText: function titleWithText({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<TitleWithText title={reference.primaryText} text={reference.secondaryText} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	shakyHand: function shakyHand({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<ShakyHand
						title={reference.primaryText}
						text={reference.secondaryText}
						button={reference.link}
						largeVideo={reference.video}
						smallVideo={reference.otherVideo}
						poster={reference.image}
					/>
				</BlockContainer>
			</InViewAnimation>
		)
	},
	blogPosts: function blogPosts({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer size="wide">
					<BlogPosts blogPosts={reference.blogPosts.map((item) => item.item!)} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	highlightedContent: function highlightedContent({ reference }) {
		return (
			<InViewAnimation>
				<BlockContainer>
					<HighlightedContent content={reference.content} />
				</BlockContainer>
			</InViewAnimation>
		)
	},
	section: function section({ reference }) {
		return (
			reference.content && <ContentRenderer content={reference.content} />
		)
	}
}

const containerDisableGuttersContext = createContext(false)
const useContainerDisableGutters = () => useContext(containerDisableGuttersContext)

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
	disableAnimations,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	const renderElement = useCallback<NonNullable<ComponentProps<typeof RichTextRenderer>['renderElement']>>(
		(element) => {
			const { type } = element.element // @TODO: fix types

			if (type === 'table') {
				return (
					<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
						<Container disableGutters={containerDisableGutters}>{element.fallback}</Container>
					</div>
				)
			}

			if (nestedTypes.includes(type)) {
				return element.fallback
			}

			if (standaloneTypes.includes(type)) {
				const { background, verticalMargin } = element.reference as Block['reference']

				return (
					<div
						className={clsx(
							styles.section,
							element.referenceType && styles[`is_reference_${element.referenceType}`],
							styles[`is_background_${background}`],
							styles[`is_vertical_margin_${verticalMargin}`],
						)}
					>
						{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
							element.fallback
						) : (
							<Container disableGutters={containerDisableGutters}>
								<div className={styles.notImplemented}>
									<div className={styles.notImplemented_name}>{element.referenceType}</div>
									is not yet implemented
								</div>
							</Container>
						)}
					</div>
				)
			}
			return (
				<div className={clsx(styles.section, styles.is_wysiwyg)}>
					<InViewAnimation>
						<Container disableGutters={containerDisableGutters}>
							<Wysiwyg>{element.fallback}</Wysiwyg>
						</Container>
					</InViewAnimation>
				</div>
			)
		},
		[containerDisableGutters],
	)

	return (
		<containerDisableGuttersContext.Provider value={containerDisableGutters}>
			<DisableInViewAnimations.Provider value={!!disableAnimations}>
			<div className={styles.wrapper}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					renderElement={renderElement}
					referenceRenderers={referenceRenderers as any /* @TODO: fix types */}
				/>
			</div>
			</DisableInViewAnimations.Provider>
		</containerDisableGuttersContext.Provider>
	)
}
