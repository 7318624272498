import { GraphQLTypes, InputType, Selector } from '../generated/contember'
import { scalarResolver } from '../utilities/createScalarResolver'

export function ImageFragment() {
	return Selector('Image')({
		id: true,
		url: true,
		width: true,
		height: true,
		alt: true,
	})
}

export type ImageResult = InputType<GraphQLTypes['Image'], ReturnType<typeof ImageFragment>, typeof scalarResolver>
