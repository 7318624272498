import { FunctionComponent, useMemo, useState } from 'react'
import { PositionListResult } from '../data/PositionListFragment'
import { isDefined } from '../utilities/isDefined'
import { Positions } from './Positions'

export interface SpecificPositionsProps {
	items: PositionListResult['items']
}

export const SpecificPositions: FunctionComponent<SpecificPositionsProps> = ({ items }) => {
	const [openPositionId, setOpenPositionId] = useState<null | string>(null)

	const positions = useMemo(() => items.map((item) => item.position).filter(isDefined), [items])

	return <Positions positions={positions} openPositionId={openPositionId} setOpenPositionId={setOpenPositionId} />
}
