import Image from 'next/image'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import { Button } from './Button'
import styles from './BlogPosts.module.sass'
import { useTranslate } from './Translations'
import { BlogPostListResult } from '../data/BlogPostListFragment'

export interface BlogPostsProps {
	blogPosts: BlogPostListResult[]
}

export const BlogPosts: FunctionComponent<BlogPostsProps> = ({ blogPosts }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.group}>
				<div className={styles.group_items}>
					{blogPosts.map((item) => item && <Item key={item.id} blogPost={item} />)}
				</div>
			</div>
		</div>
	)
}

const Item: FunctionComponent<{
	blogPost: BlogPostListResult
}> = ({ blogPost }) => {
	const translate = useTranslate()

	return (
		<article className={styles.item}>
			<div className={styles.item_background}>
				{blogPost?.wideImage && (
					<Image
						src={blogPost.wideImage.url}
						alt={blogPost.wideImage.alt ?? ''}
						fill
						className={styles.item_background_in}
						sizes="400px"
					/>
				)}
			</div>
			{blogPost?.publishedAt && (
				<time dateTime={blogPost.publishedAt} className={styles.item_date}>
					{new Date(blogPost.publishedAt).toLocaleDateString()}
				</time>
			)}
			{blogPost?.localesByLocale?.title && <h4 className={styles.item_title}>{blogPost.localesByLocale.title}</h4>}
			<div className={styles.item_note}>
				{blogPost?.author?.name}
			</div>
			{blogPost?.localesByLocale?.link &&
				<div className={styles.item_action}>
					<div className={styles.item_action_button}>
						<Button type="link" href={blogPost.localesByLocale.link.url} variant="secondary" size="small">
							{translate('blogPost.read')}
						</Button>
					</div>
					<Link href={blogPost.localesByLocale.link.url} className={styles.item_action_link} aria-label="" />
				</div>
			}
		</article>
	)
}
