import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isSpecialKeyPressed } from '../utilities/isSpecialKeyPressed'
import styles from './CollapsibleBox.module.sass'
import { useTranslate } from './Translations'

export type CollapsibleBoxProps = {
	head: ReactNode
	hidingHead?: boolean
	children: ReactNode
	href?: string
	onOpen?: () => void
} & (
	| {}
	| {
			isOpen: boolean
			onRequestOpen: () => void
			onRequestClose: () => void
	  }
)

export const CollapsibleBox: FunctionComponent<CollapsibleBoxProps> = ({
	head,
	hidingHead = false,
	children,
	href,
	onOpen,
	...otherProps
}) => {
	const open = useCallback(() => {
		if ('onRequestOpen' in otherProps) {
			otherProps.onRequestOpen()
		} else {
			setIsOpenLocal(true)
		}
	}, [otherProps])
	const close = useCallback(() => {
		if ('onRequestClose' in otherProps) {
			otherProps.onRequestClose()
		} else {
			setIsOpenLocal(false)
		}
	}, [otherProps])

	const [isOpenLocal, setIsOpenLocal] = useState('isOpen' in otherProps ? otherProps.isOpen : false)
	const handleToggleClick = useCallback(() => {
		if (isOpenLocal) {
			close()
		} else {
			open()
		}
	}, [close, isOpenLocal, open])

	useEffect(() => {
		if ('isOpen' in otherProps) {
			setIsOpenLocal(otherProps.isOpen)
		}
	}, [otherProps])

	const ref = useRef<HTMLDivElement>(null)
	const handleTransitionEnd = useCallback((open: boolean) => {
		if (open) {
			ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		}
	}, [])

	return (
		<div className={clsx(styles.wrapper, isOpenLocal && styles.is_open, hidingHead && styles.is_hidingHead)} ref={ref}>
			<div className={styles.head}>
				<div className={styles.head_in}>
					{hidingHead ? <Collapsible open={!isOpenLocal}>{head}</Collapsible> : head}
				</div>
				<Toggle isOpen={isOpenLocal} href={href} handleToggle={handleToggleClick} />
			</div>
			<Collapsible open={isOpenLocal} revealType="topFirst" onTransitionEnd={handleTransitionEnd}>
				<div className={styles.content}>{children}</div>
			</Collapsible>
		</div>
	)
}

const Toggle: FunctionComponent<Pick<CollapsibleBoxProps, 'href'> & { isOpen: boolean; handleToggle: () => void }> = ({
	isOpen,
	href,
	handleToggle,
}) => {
	const { replace } = useRouter()
	const translate = useTranslate()
	const content = useMemo(
		() => (
			<>
				<span className={styles.toggle_line} />
				<span className={styles.toggle_line} />
			</>
		),
		[],
	)
	const className = styles.toggle
	const label = translate(isOpen ? 'collapsibleBox.collapse' : 'collapsibleBox.expand')

	if (href) {
		return (
			<Link
				href={href}
				aria-label={label}
				className={className}
				onClick={(event) => {
					if (isSpecialKeyPressed(event.nativeEvent)) {
						return
					}
					event.preventDefault()
					if (!isOpen) {
						replace(href, undefined, { shallow: true })
					}
					handleToggle()
				}}
			>
				{content}
			</Link>
		)
	}

	return (
		<button
			type="button"
			aria-label={label}
			className={className}
			onClick={() => {
				handleToggle()
			}}
		>
			{content}
		</button>
	)
}
