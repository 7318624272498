import type { FunctionComponent } from 'react'
import { Icon } from './Icon'
import styles from './Lock.module.sass'

export const Lock: FunctionComponent = () => {
	return (
		<div className={styles.wrapper}>
			<Icon name="lock" />
		</div>
	)
}
