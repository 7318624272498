import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { PositionLocalePreviewResult } from '../data/PositionLocalePreviewFragment'
import { CollapsibleBox } from './CollapsibleBox'
import { PositionDetail } from './PositionDetail'
import styles from './Positions.module.sass'

export interface PositionsProps {
	positions: PositionLocalePreviewResult[]
	openPositionId: string | null
	setOpenPositionId: (id: string | null) => void
}

export const Positions: FunctionComponent<PositionsProps> = ({ positions, openPositionId, setOpenPositionId }) => {
	return (
		<div className={styles.wrapper}>
			{positions.map((position) => (
				<Position
					key={position.id}
					isOpen={position.id === openPositionId}
					position={position}
					onRequestOpen={() => setOpenPositionId(position.id)}
					onRequestClose={() => setOpenPositionId(null)}
				/>
			))}
		</div>
	)
}

const Position: FunctionComponent<{
	position: PositionLocalePreviewResult
	isOpen: boolean
	onRequestOpen: () => void
	onRequestClose: () => void
}> = ({ position, isOpen, onRequestOpen, onRequestClose }) => {
	return (
		<div className={styles.item}>
			<CollapsibleBox
				isOpen={isOpen}
				onRequestOpen={onRequestOpen}
				onRequestClose={onRequestClose}
				href={position.link?.url}
				head={
					<div className={styles.head}>
						{position.title && <h4 className={styles.head_title}>{position.title}</h4>}
						<div className={styles.head_tags}>
							{position.root?.tags?.items.map(
								({ id, tag }) =>
									tag?.localesByLocale?.title && (
										<span key={id} className={styles.head_tag}>
											{tag.localesByLocale.title}
										</span>
									),
							)}
						</div>
						{position.salaryRange && (
							<div className={styles.head_salary_range}>
								{position.salaryRange}
							</div>
						)}
						{position.summary && (
							<div className={styles.head_summary}>
								<RichTextRenderer source={position.summary} />
							</div>
						)}
					</div>
				}
				hidingHead
			>
				<div className={styles.content}>
					<PositionDetail title={position.title} content={position.content} salaryRange={position.salaryRange} />
				</div>
			</CollapsibleBox>
		</div>
	)
}
