import type { FunctionComponent } from 'react'
import { Contact, ContactProps } from './Contact'
import styles from './ContactBlock.module.sass'

export interface ContactBlockProps extends ContactProps {}

export const ContactBlock: FunctionComponent<ContactBlockProps> = (props) => {
	return (
		<div className={styles.wrapper}>
			<Contact {...props} />
		</div>
	)
}
