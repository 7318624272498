import { GraphQLTypes, InputType, Selector } from '../generated/contember'
import { scalarResolver } from '../utilities/createScalarResolver'
import { ImageFragment } from './ImageFragment'
import { TagFragment } from './TagFragment'

export function CaseStudyLocalePreviewFragment(locale: string) {
	return Selector('CaseStudyLocale')({
		title: true,
		note: true,
		link: [
			{},
			{
				url: true,
			},
		],
		root: [
			{},
			{
				wideImage: [{}, ImageFragment()],
				narrowImage: [{}, ImageFragment()],
				tags: [
					{},
					{
						id: true,
						tag: [{}, TagFragment(locale)],
					},
				],
			},
		],
	})
}

export type CaseStudyLocalePreviewResult = InputType<
	GraphQLTypes['CaseStudyLocale'],
	ReturnType<typeof CaseStudyLocalePreviewFragment>,
	typeof scalarResolver
>
