import { FunctionComponent, useState } from 'react'
import { toast } from 'react-toastify'
import { useMirrorLoading } from 'shared-loading-indicator'
import { SubscribeFormResult } from '../data/SubscribeFormFragment'
import { api } from '../utilities/api'
import { Button, ButtonProps } from './Button'
import { CheckboxConsent } from './CheckboxConsent'
import { useForm } from './FormProvider'
import { Input } from './Input'
import { useLocale } from './LocaleProvider'
import styles from './SubscribeForm.module.sass'
import { useTranslate } from './Translations'

const FILE_SIZE_LIMIT = 3 * 1024 * 1024
const FILE_SIZE_LIMIT_TEXT = '3MB'

export interface SubscribeFormProps extends SubscribeFormResult {
	submitButtonVariant?: ButtonProps['variant']
}

export const SubscribeForm: FunctionComponent<SubscribeFormProps> = ({ id, datacruitJobId, submitButtonVariant }) => {
	const translate = useTranslate()
	const locale = useLocale()
	const { formPrivacyPolicy, formMarketingConsent } = useForm()

	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [marketingConsent, setMarketingConsent] = useState(false)
	const [privacyPolicyConsent, setPrivacyPolicyConsent] = useState(false)
	const [url, setUrl] = useState('')
	const [message, setMessage] = useState('')
	const [attachmentKey, setAttachmentKey] = useState(0)
	const resetAttachment = () => setAttachmentKey((key) => key + 1);
	const [attachment, setAttachment] = useState<
		| undefined
		| {
				fileName: string
				attachment: string
		  }
	>(undefined)
	const mutation = api.subscribe.send.useMutation({
		onSuccess: () => {
			toast(translate('subscribeForm.success'), {
				type: 'success',
			})
			setEmail('')
			setFirstName('')
			setLastName('')
			setUrl('')
			setMarketingConsent(false)
			setPrivacyPolicyConsent(false)
			setAttachment(undefined)
			setMessage('')
			resetAttachment()
		},
		onError: () => {
			toast(translate('subscribeForm.error'), {
				type: 'error',
			})
		},
	})
	const { isLoading } = mutation
	useMirrorLoading(isLoading)

	return (
		<form
			className={styles.wrapper}
			onSubmit={(event) => {
				event.preventDefault()
				mutation.mutate({
					formId: id,
					firstName,
					lastName,
					email,
					consent: marketingConsent,
					locale,
					datacruitJobId: datacruitJobId,
					url,
					attachment,
					message,
				})
			}}
		>
			<div className={styles.narrow}>
				<Input
					required
					type="text"
					label={translate('subscribeForm.firstName.label')}
					readOnly={isLoading}
					value={firstName}
					onChange={(event) => {
						setFirstName(event.target.value)
					}}
					autoComplete="given-name"
				/>
			</div>
			<div className={styles.narrow}>
				<Input
					required
					type="text"
					label={translate('subscribeForm.lastName.label')}
					readOnly={isLoading}
					value={lastName}
					onChange={(event) => {
						setLastName(event.target.value)
					}}
					autoComplete="family-name"
				/>
			</div>
			<div className={styles.wide}>
				<Input
					required
					type="email"
					label={translate('subscribeForm.email.label')}
					readOnly={isLoading}
					value={email}
					onChange={(event) => {
						setEmail(event.target.value)
					}}
					autoComplete="email"
				/>
			</div>
			<div className={styles.wide}>
				<Input
					type="url"
					label={translate('subscribeForm.url.label')}
					readOnly={isLoading}
					value={url}
					onChange={(event) => {
						setUrl(event.target.value)
					}}
					autoComplete="url"
				/>
			</div>
			<div className={styles.wide}>
				<Input
					type="file"
					key={attachmentKey}
					label={`${translate('subscribeForm.file.label')} (${translate('subscribeForm.file.maxSize')} ${FILE_SIZE_LIMIT_TEXT})`}
					readOnly={isLoading}
					onChange={async (event) => {
						if (!(event.target instanceof HTMLInputElement) || event.target.files === null) {
							return
						}
						if (event.target.files.length === 0) {
							setAttachment(undefined)
						} else {
							const file = event.target.files[0]

							if (file.size > FILE_SIZE_LIMIT) {
								toast(translate('subscribeForm.file.sizeError'), {
									type: 'error',
								})
								resetAttachment()
								return
							}

							const attachment = await new Promise<string>((resolve, reject) => {
								const fileReader = new FileReader()
								fileReader.readAsDataURL(file)
								fileReader.onload = () => {
									resolve(fileReader.result as string)
								}
								fileReader.onerror = (error) => {
									reject(error)
								}
							})
							setAttachment({
								fileName: file.name,
								attachment,
							})
						}
					}}
				/>
			</div>
			<div className={styles.wide}>
				<Input
					label={translate('subscribeForm.text.label')}
					readOnly={isLoading}
					value={message}
					onChange={(event) => {
						setMessage(event.target.value)
					}}
					multiline
				/>
			</div>
			{formPrivacyPolicy && (
				<div className={styles.wide}>
					<CheckboxConsent
						required
						checked={privacyPolicyConsent}
						onChange={(checked) => {
							setPrivacyPolicyConsent(checked)
						}}
						rawRichText={formPrivacyPolicy}
					/>
				</div>
			)}
			{formMarketingConsent && (
				<div className={styles.wide}>
					<CheckboxConsent
						checked={marketingConsent}
						onChange={(checked) => {
							setMarketingConsent(checked)
						}}
						rawRichText={formMarketingConsent}
					/>
				</div>
			)}
			<div className={styles.submit}>
				<Button type="submit" variant={submitButtonVariant} disabled={isLoading} isFullWidth>
					{translate('subscribeForm.submit')}
				</Button>
			</div>
		</form>
	)
}
