import clsx from 'clsx'
import Image from 'next/image'
import { CSSProperties, FunctionComponent, useMemo } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { ImageListResult } from '../data/ImageListFragment'
import { Container } from './Container'
import styles from './LogoList.module.sass'
import { SectionTitle } from './SectionTitle'
import { ContemberImage } from './ContemberImage'

type Images = ImageListResult['items']

export interface LogoListProps {
	title?: string
	lightThemeImages: Images
	darkThemeImages: Images
}

export const LogoList: FunctionComponent<LogoListProps> = ({ title, lightThemeImages, darkThemeImages }) => {
	return (
		<div className={styles.wrapper}>
			{title && (
				<Container>
					<SectionTitle>{title}</SectionTitle>
				</Container>
			)}
			<div className={styles.in}>
				<Items images={lightThemeImages} theme="light" />
				<Items images={darkThemeImages} theme="dark" />
			</div>
		</div>
	)
}

const Items: FunctionComponent<{
	images: Images
	theme: 'light' | 'dark'
}> = ({ theme, images }) => {
	return (
		<div className={clsx(styles.items, styles[`is_theme_${theme}`])}>
			{images.map(({ id, image }) => image && <Item key={id} image={image} />)}
		</div>
	)
}

const Item: FunctionComponent<{ image: ImageResult }> = ({ image }) => {
	const { width = 1, height = 1 } = image
	const volume = useMemo(
		() => (width * height) / Math.pow(Math.max(width, ((height - width) / (height / width)) * 2), 2),
		[height, width],
	)
	const aspectRatio = useMemo(() => width / height, [height, width])

	return (
		<div
			className={styles.item}
			style={
				{
					['--LogoList-aspectRatio']: aspectRatio,
					['--LogoList-volume']: volume,
				} as CSSProperties
			}
		>
			<ContemberImage className={styles.image} image={image} sizes="260px" />
		</div>
	)
}
