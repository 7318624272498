import type { FunctionComponent } from 'react'
import { PositionLocaleDetailResult } from '../data/PositionLocaleDetailFragment'
import { Container } from './Container'
import { PositionDetail } from './PositionDetail'
import styles from './PositionPage.module.sass'
import { ShowAllLink } from './ShowAllLink'
import { usePositionsLink } from './SpecialLinksProvider'

export interface PositionPageProps extends PositionLocaleDetailResult {}

export const PositionPage: FunctionComponent<PositionPageProps> = ({ title, content, salaryRange }) => {
	const showAllLink = usePositionsLink()

	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.in}>
					<div className={styles.showAllSmall}>{showAllLink && <ShowAllLink link={showAllLink} variant="small" />}</div>
					<PositionDetail title={title} content={content} salaryRange={salaryRange} />
				</div>
				{showAllLink && <ShowAllLink link={showAllLink} />}
			</Container>
		</div>
	)
}
