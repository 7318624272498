import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import Modal from 'react-modal'
import { useMedia } from 'react-use'
import { TalkResult } from '../data/TalkFragment'
import { TalkDate } from './TalkDate'
import styles from './TalkModal.module.sass'
import { useTranslate } from './Translations'
import clsx from 'clsx'
import { barlow, barlowCondensed } from '../utilities/fonts'
import { ContemberImage } from './ContemberImage'

export interface TalkModalProps {
	isOpen: boolean
	talk: TalkResult | undefined
	onRequestClose: () => void
}

export const TalkModal: FunctionComponent<TalkModalProps> = ({ isOpen, talk, onRequestClose }) => {
	const reducedMotionNoPreference = useMedia('(prefers-reduced-motion: no-preference)', true)
	const translate = useTranslate()

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			closeTimeoutMS={reducedMotionNoPreference ? 300 : 0 /* Same in styles */}
			className={clsx(styles.modal, barlow.variable, barlowCondensed.variable)}
			overlayClassName={styles.modal_overlay}
		>
			{talk && (
				<div className={styles.wrapper}>
					<div className={styles.cover}>
						{talk.coverImage && (
							<ContemberImage
								image={talk.coverImage}
								className={styles.cover_image}
								sizes="(min-width: 704px) 704px, 100vw"
							/>
						)}
						<div className={styles.head}>
							<div className={styles.close}>
								<button
									type="button"
									onClick={onRequestClose}
									aria-label={translate('modal.close')}
									className={styles.close_in}
								/>
							</div>
							{talk.date && (
								<div className={styles.date}>
									<TalkDate isoDate={talk.date} />
								</div>
							)}
							{talk.title && <h4 className={styles.title}>{talk.title}</h4>}
							{talk.note && (
								<div className={styles.note}>
									<RichTextRenderer source={talk.note} />
								</div>
							)}
						</div>
					</div>
					<div className={styles.content}>
						{talk.content && <RichTextRenderer source={talk.content} />}
						{talk.otherImage && (
							<ContemberImage
								image={talk.otherImage}
								className={styles.otherImage}
								sizes="(min-width: 704px) 704px, 100vw"
							/>
						)}
					</div>
				</div>
			)}
		</Modal>
	)
}
