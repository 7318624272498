import clsx from 'clsx'
import { createContext, FunctionComponent, ReactNode, useContext, useRef } from 'react'
import { useIsInViewOnce } from '../utilities/useIsInViewOnce'
import styles from './InViewAnimation.module.sass'

export const DisableInViewAnimations = createContext(false)

export interface InViewAnimationProps {
	children: ReactNode
}

export const InViewAnimation: FunctionComponent<InViewAnimationProps> = ({ children }) => {
	const ref = useRef<HTMLDivElement>(null)
	const isInView = useIsInViewOnce(ref)
	const disableInViewAnimations = useContext(DisableInViewAnimations)

	if (disableInViewAnimations) {
		return <>{children}</>
	}

	return (
		<div className={clsx(styles.wrapper, isInView && styles.is_inView)}>
			<div className={styles.detector} ref={ref} />
			{children}
		</div>
	)
}
