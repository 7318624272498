import { useRouter } from 'next/router'
import { FunctionComponent, useMemo } from 'react'
import { ContentBlockReferencesWithoutRecursionResult } from '../data/ContentBlockReferencesWithoutRecursionFragment'
import { TagResult } from '../data/TagFragment'
import { tagSlugParameterName, useActiveTagSlugParameter } from '../utilities/useActiveTagSlugParameter'
import styles from './CaseStudies.module.sass'
import { CaseStudyTile } from './CaseStudyTile'
import { Tag } from './Tag'

export interface CaseStudiesProps {
	tags: Array<{
		id: string
		item?: TagResult | undefined
	}>
	caseStudies: ContentBlockReferencesWithoutRecursionResult['caseStudies']
}

export const CaseStudies: FunctionComponent<CaseStudiesProps> = ({ tags, caseStudies }) => {
	const activeSlug = useActiveTagSlugParameter()
	const activeCaseStudies = useMemo(
		() =>
			caseStudies.filter(
				(caseStudy) =>
					activeSlug === null ||
					caseStudy.item?.localesByLocale?.root?.tags.some(({ tag }) => tag?.localesByLocale?.slug === activeSlug),
			),
		[activeSlug, caseStudies],
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.tags}>
				{tags.map(
					({ id, item }) =>
						item?.localesByLocale && (
							<TagItem key={id} item={item} isActive={activeSlug === item.localesByLocale.slug} />
						),
				)}
			</div>
			<div className={styles.items}>
				{activeCaseStudies.map(
					({ id, item }) =>
						item?.localesByLocale && (
							<div key={id} className={styles.item}>
								<CaseStudyTile showTags layout="wide" {...item.localesByLocale} />
							</div>
						),
				)}
			</div>
		</div>
	)
}

const TagItem: FunctionComponent<{ item: TagResult; isActive: boolean }> = ({ item, isActive }) => {
	const router = useRouter()
	const query = useMemo(
		() =>
			Object.fromEntries(
				Object.entries({
					...router.query,
					[tagSlugParameterName]: isActive ? null : item.localesByLocale?.slug,
				}).filter(([_, value]) => value !== null),
			),
		[isActive, item.localesByLocale?.slug, router.query],
	)

	return item?.localesByLocale ? (
		<Tag
			label={item.localesByLocale.title}
			isActive={isActive}
			isFilled
			href={{
				pathname: router.pathname,
				query,
			}}
		/>
	) : null
}
