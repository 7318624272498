import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { CaseStudyLocalePreviewResult } from '../data/CaseStudyLocalePreviewFragment'
import styles from './CaseStudyTile.module.sass'
import { Tag } from './Tag'

export interface CaseStudyTileProps extends CaseStudyLocalePreviewResult {
	showTags?: boolean
	layout: 'wide' | 'narrow'
}

export const CaseStudyTile: FunctionComponent<CaseStudyTileProps> = ({
	title,
	note,
	link,
	root,
	showTags = false,
	layout,
}) => {
	const { tags = [], wideImage, narrowImage } = root ?? {}
	showTags = showTags && tags.length > 0

	return (
		<div className={clsx(styles.wrapper, styles[`is_layout_${layout}`], showTags && styles.is_showTags)}>
			<div className={styles.background}>
				<div className={styles.background_wide}>
					{wideImage && (
						<Image
							src={wideImage.url}
							className={styles.background_image}
							fill
							alt={wideImage.alt ?? ''}
							sizes="(min-width: 1140px) 1140px, 100vw"
						/>
					)}
				</div>
				<div className={styles.background_narrow}>
					{narrowImage && (
						<Image
							src={narrowImage.url}
							className={styles.background_image}
							fill
							alt={narrowImage.alt ?? ''}
							sizes="(min-width: 400px) 400px, 100vw"
						/>
					)}
				</div>
			</div>
			{title && (
				<h3 className={styles.title}>
					{link?.url ? (
						<Link href={link.url} className={styles.link}>
							{title}
						</Link>
					) : (
						title
					)}
				</h3>
			)}
			{showTags && (
				<div className={styles.tags}>
					{tags.map(({ id, tag }) => tag?.localesByLocale && <Tag key={id} label={tag.localesByLocale.title} />)}
				</div>
			)}
			{note && (
				<div className={styles.note}>
					<RichTextRenderer source={note} />
				</div>
			)}
		</div>
	)
}
