import { RichTextRenderer } from '@contember/react-client'
import { CSSProperties, FunctionComponent, useMemo, useRef } from 'react'
import { isDefined } from '../utilities/isDefined'
import { Avatar } from './Avatar'
import styles from './TestimonialsFull.module.sass'
import { TestimonialFullListResult } from '../data/TestimonialFullListFragment'
import { InViewAnimation } from './InViewAnimation'
import Image from 'next/image'
import { ContemberImage } from './ContemberImage'
import clsx from 'clsx'

export interface TestimonialsFullProps {
	items: TestimonialFullListResult['items']
}

export const TestimonialsFull: FunctionComponent<TestimonialsFullProps> = ({ items }) => {
	// @TODO: improve testimonial? type which should be defined
	const nonEmptyItems = useMemo(
		() => items.map((item) => (item.testimonial ? item : undefined)).filter(isDefined),
		[items],
	)

	return (
		<div className={styles.wrapper}>
			{nonEmptyItems.map(
				({ id, testimonial }) =>
					testimonial && (
						<Testimonial key={id} testimonial={testimonial} />
					),
			)}
		</div>
	)
}

type TestimonialProps = {
	testimonial: NonNullable<TestimonialFullListResult['items'][number]['testimonial']>
}

const Testimonial = ({ testimonial }: TestimonialProps) => {
	const { width = 1, height = 1 } = testimonial.avatar ?? {}
	const volumeCoefficient = useMemo(
		() => (width * height) / Math.pow(Math.max(width, ((height - width) / (height / width)) * 2), 2),
		[height, width],
	)
	const aspectRatio = width / height
	const isImageLandscape = aspectRatio > 1

	return (
		<div className={styles.itemWrapper}>
			<InViewAnimation>
				<div className={styles.item}>
					<div className={styles.quote}>&ldquo;</div>
					{testimonial.content && (
						<div className={styles.content}>
							<RichTextRenderer source={testimonial.content} />
						</div>
					)}
					<div className={clsx(styles.authorWrapper, testimonial.imageVariant === 'logo' && isImageLandscape && styles.isVertical)}>
						{testimonial.avatar && (
							testimonial.imageVariant === 'avatar'
								? (
									<div className={styles.avatar}>
										<Avatar image={testimonial.avatar} />
									</div>
								)
								: (
									<div
										className={clsx(styles.logo, !isImageLandscape && styles.portrait)}
										style={{
											['--Testimonial-volume-coef']: volumeCoefficient,
											['--Testimonial-aspect-ratio']: aspectRatio
										} as CSSProperties}
									>
										<ContemberImage className={styles.logoImage} image={testimonial.avatar} sizes="260px" />
									</div>
								)
						)}
						<div className={styles.authorDetails}>
							{testimonial.name && <div className={styles.name}>{testimonial.name}</div>}
							{testimonial.note && <div className={styles.note}>{testimonial.note}</div>}
						</div>
					</div>
				</div>
			</InViewAnimation>
		</div>
	)
}
