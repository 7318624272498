import { RichTextRenderer } from '@contember/react-client'
import { FunctionComponent, useMemo, useState } from 'react'
import { InformationListResult } from '../data/InformationListFragment'
import styles from './Catchwords.module.sass'
import { CollapsibleBox } from './CollapsibleBox'

export interface CatchwordsProps {
	items: InformationListResult['items']
}

export const Catchwords: FunctionComponent<CatchwordsProps> = ({ items }) => {
	const [openItemId, setOpenItemId] = useState<null | string>(null)

	const columns = useMemo(() => {
		const columns: InformationListResult['items'][] = [[], [], []]
		items.forEach((item, index) => {
			columns[index % 3].push(item)
		})
		return columns
	}, [items])

	return (
		<div className={styles.wrapper}>
			{columns.map((items, i) => (
				<div key={i} className={styles.column}>
					{items.map((item) => (
						<div key={item.id} className={styles.item}>
							<CollapsibleBox
								isOpen={item.id === openItemId}
								onRequestOpen={() => setOpenItemId(item.id)}
								onRequestClose={() => setOpenItemId(null)}
								head={<>{item.title && <h4 className={styles.item_title}>{item.title}</h4>}</>}
							>
								{item.description && (
									<div className={styles.item_description}>
										<RichTextRenderer source={item.description} />
									</div>
								)}
							</CollapsibleBox>
						</div>
					))}
				</div>
			))}
		</div>
	)
}
