import clsx from 'clsx'
import Image from 'next/image'
import { CSSProperties, FunctionComponent, ReactNode, useMemo } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { useParallax } from '../utilities/useParallax'
import { Container } from './Container'
import styles from './Hero.module.sass'

export interface HeroProps {
	size?: 'compact' | 'normal' | 'narrow'
	wideImage: ImageResult | undefined
	narrowImage: ImageResult | undefined
	children?: ReactNode
	background?: ReactNode
	parallax?: boolean
	mobileContentAlignment?: 'end' | 'center'
}

export const Hero: FunctionComponent<HeroProps> = ({
	children,
	size = 'normal',
	wideImage,
	narrowImage,
	background,
	parallax = false,
	mobileContentAlignment = 'end'
}) => {
	const wideImageContent = useMemo(
		() =>
			wideImage && (
				<Image
					src={wideImage.url}
					className={styles.background_image}
					fill
					priority
					alt={wideImage.alt ?? ''}
					sizes="(min-width: 2400px) 2400px, 100vw"
				/>
			),
		[wideImage],
	)

	return (
		<Container size="fullWidth" disableGutters>
			<div className={clsx(styles.wrapper, styles[`is_size_${size}`], parallax && styles.is_parallax, styles[`content_align_mobile_${mobileContentAlignment}`])}>
				<div className={clsx(styles.background)}>
					<div className={styles.background_narrow}>
						{narrowImage && (
							<Image
								src={narrowImage.url}
								className={styles.background_image}
								fill
								priority
								alt={narrowImage.alt ?? ''}
								sizes="100vw"
							/>
						)}
					</div>
					<div className={styles.background_wide}>
						{parallax ? <Parallax>{wideImageContent}</Parallax> : wideImageContent}
					</div>
					{background && <div className={styles.background_content}>{background}</div>}
				</div>
				<div className={styles.content}>{children}</div>
			</div>
		</Container>
	)
}

const Parallax: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const parallax = useParallax()

	return (
		<div
			className={styles.parallax}
			style={
				{
					'--Hero-parallax-x': `${parallax.x}`,
					'--Hero-parallax-y': `${parallax.y}`,
				} as CSSProperties
			}
		>
			{children}
		</div>
	)
}
