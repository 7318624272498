import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { ImageResult } from '../data/ImageFragment'
import styles from './Avatar.module.sass'
import { ContemberImage } from './ContemberImage'

export interface AvatarProps {
	image: ImageResult
}

export const Avatar: FunctionComponent<AvatarProps> = ({ image }) => {
	return (
		<div className={styles.wrapper}>
			<ContemberImage className={styles.in} image={image} fill  sizes="160px" />
		</div>
	)
}
