import { RichTextRenderer } from '@contember/react-client'
import { FunctionComponent, useMemo, useRef } from 'react'
import { TestimonialListResult } from '../data/TestimonialListFragment'
import { isDefined } from '../utilities/isDefined'
import { Avatar } from './Avatar'
import { Container } from './Container'
import { SlidesArrows } from './SlidesArrows'
import styles from './Testimonials.module.sass'

export interface TestimonialsProps {
	items: TestimonialListResult['items']

	// @TODO: add disable gutters for ContentRenderer
}

export const Testimonials: FunctionComponent<TestimonialsProps> = ({ items }) => {
	const slidesRef = useRef<HTMLDivElement>(null)

	// @TODO: improve testimonial? type which should be defined
	const nonEmptyItems = useMemo(
		() => items.map((item) => (item.testimonial ? item : undefined)).filter(isDefined),
		[items],
	)

	return (
		<div className={styles.wrapper}>
			{nonEmptyItems.length > 1 && (
				<div className={styles.navigation}>
					<Container size="wide">
						<SlidesArrows slidesRef={slidesRef} />
					</Container>
				</div>
			)}
			<div className={styles.in}>
				<div className={styles.items} ref={slidesRef}>
					{nonEmptyItems.map(
						({ id, testimonial }) =>
							testimonial && (
								<div key={id} className={styles.item}>
									{testimonial.avatar && (
										<div className={styles.avatar}>
											<Avatar image={testimonial.avatar} />
										</div>
									)}
									{testimonial.note && <div className={styles.note}>{testimonial.note}</div>}
									{testimonial.content && (
										<div className={styles.content}>
											<RichTextRenderer source={testimonial.content} />
										</div>
									)}
								</div>
							),
					)}
				</div>
			</div>
		</div>
	)
}
