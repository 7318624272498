import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { LinkListResult } from '../data/LinkListFragment'
import { contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import styles from './EventHeader.module.sass'
import { Hero } from './Hero'

export interface EventHeaderProps {
	title: string | undefined
	note?: string
	links: LinkListResult['items']
	wideImage: ImageResult | undefined
	narrowImage: ImageResult | undefined
}

export const EventHeader: FunctionComponent<EventHeaderProps> = ({ title, note, links, wideImage, narrowImage }) => {
	return (
		<Hero wideImage={wideImage} narrowImage={narrowImage} size="compact">
			<Container size="wide">
				<div className={styles.wrapper}>
					{title && (
						<h2 className={styles.title}>
							<RichTextRenderer source={title} />
						</h2>
					)}
					{note && (
						<div className={styles.note}>
							<RichTextRenderer source={note} />
						</div>
					)}
					{links.length > 0 && (
						<div className={styles.links}>
							{links.map(
								({ id, link }, i) =>
									link && (
										<div key={id} className={styles.link}>
											<Button
												type="link"
												{...contemberLinkToHrefTargetRel(link)}
												variant={i === 0 ? 'primary' : 'secondary'}
											>
												{link.title}
											</Button>
										</div>
									),
							)}
						</div>
					)}
				</div>
			</Container>
		</Hero>
	)
}
