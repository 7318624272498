import Image from 'next/image'
import { CSSProperties, FunctionComponent, useRef } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { ImageListResult } from '../data/ImageListFragment'
import { Container } from './Container'
import styles from './Gallery.module.sass'
import { SlidesArrows } from './SlidesArrows'

export interface GalleryProps {
	items: ImageListResult['items']
}

export const Gallery: FunctionComponent<GalleryProps> = ({ items }) => {
	const slidesRef = useRef<HTMLDivElement>(null)

	return (
		<div className={styles.wrapper}>
			<div className={styles.navigation}>
				<Container size="wide">
					<SlidesArrows slidesRef={slidesRef} />
				</Container>
			</div>
			<div className={styles.in}>
				<div className={styles.items} ref={slidesRef}>
					{items.map(({ id, image }) => image && <Item image={image} key={id} />)}
				</div>
			</div>
		</div>
	)
}

const Item: FunctionComponent<{ image: ImageResult }> = ({ image: { width = 1, height = 1, alt = '', url } }) => {
	const aspectRatio = width / height

	return (
		<figure
			className={styles.item}
			style={
				{
					[`--Gallery-aspectRatio`]: `${aspectRatio}`,
				} as CSSProperties
			}
		>
			<div className={styles.image}>
				<Image className={styles.image_in} src={url} fill alt={alt ?? ''} sizes={`${480 * aspectRatio}px`} />
			</div>
			{alt && <figcaption className={styles.caption}>{alt}</figcaption>}
		</figure>
	)
}
