import clsx from 'clsx'
import type { DetailedHTMLProps, FunctionComponent, InputHTMLAttributes, ReactNode } from 'react'
import styles from './Checkbox.module.sass'

export type CheckboxProps = Pick<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'required' | 'checked' | 'onChange'
> & {
	children: ReactNode
	blandLinks?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
	children,
	required,
	checked,
	blandLinks = false,
	onChange,
}) => {
	return (
		<label className={clsx(styles.wrapper, blandLinks && styles.is_blandLinks)}>
			<input type="checkbox" className={styles.input} required={required} checked={checked} onChange={onChange} />
			<div className={styles.symbol} />
			<div className={styles.label}>{children}</div>
		</label>
	)
}
